<template>
  <div>
    <b-card id="card-twitch-filters" class="mb-0">
      <select-network-search 
        :networks_avaibles="networks_avaibles"
        :select_network_dad="select_network"
        @update_select_network="updateSelectNetwork"
        class="select-network-search"
      ></select-network-search>
      <b-row class="m-0 w-100">
      <!-- Audience location -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_audience_location">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <span class="search-Map-Pin4"></span> {{$t("search.audience_location")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_audience_location}} </p>
              </div>
            </template>
            <b-dropdown-header :key="update_location" class="b-dropdown-header-location-filter-padding">
              <div v-for="(location, index) in audience_locations" :key="index">
                <audience-location-filter
                  :key="change_audience_location"
                  :show_remove="index > 0"
                  :index="index"
                  :location="location"
                  :only_countries="true"
                  :main_locations_filter="main_locations_filter"
                  @changeLocationAudienceChild="changeLocationAudience"
                  @removeAudienceLocationChild="removeAudienceLocation"
                />
              </div>
              <div v-if="Object.keys(this.audience_locations[0]).length > 0" class="p-custom">
                <hr/>
                <b-link v-show="this.audience_locations.length < 3" @click="addAudienceLocation()" class="text-blue">{{$t('search.addLocation')}}</b-link>
                <div v-show="this.audience_locations.length === 3" class="white-space-wrap">{{$t('search.max3Locations')}}</div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>
        
      <!-- Audience size -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light mb-1 d-block">
                    <span class="search-Users"></span>{{$t("search.audience_size")}}
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_followers}} </p>
              </div>
            </template>
            <b-dropdown-header>
              <followers-filter @changeFollowers="changeFollowers" :key="update_followers_filter"/>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Viewers average -->
        <b-col cols="12" class="col-md-6 col-lg filter-outline p-0">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light mb-1 d-block">
                    <b-icon class="search-icon icon-size-14" icon="eye"/><span class="ml-1">{{$t("search.average_viewers")}}</span>
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{average_viewers[0] === 0 && average_viewers[1] === 20000 ? $t('search.realty.any') : `${getFormat(average_viewers[0])} - ${getFormat(average_viewers[1])}`}}</p>
              </div>
            </template>
            <b-dropdown-header>
              <view-avg-slider 
                :init_range="average_viewers" 
                :interval="50" 
                :max_value="20000" 
                :min_value="0"
                :options_numbers="[50, 250, 1000, 5000, 10000, 20000]"
                identifier="average_viewers" 
                @changeSlide="changeSlideComponent" 
              />
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Boton more filters -->
        <div class="d-flex align-items-center container-more-filters">
          <b-button variant="outline-secondary" class="d-flex align-items-center w-100" @click="open_sidebar = true"><b-icon icon="filter" class="mr-03"/>{{$t('search.more_filters')}}</b-button>
        </div>
      </b-row>
    </b-card>
    <search-by-actions
      :data="{
        total_results,
        loading,
        searched,
        no_categories: true
      }"
      @sendFilters="sendFilters()"
      @add_keyword_filter="addKeywordFilter"
      ref="search_by_actions"
    ></search-by-actions>

    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter" @updateModel="updateModel" class="mt-1 d-block col-xl-9 col-md-6 col-lg-6 col-12"/>
    <b-sidebar 
      v-model="open_sidebar"
      backdrop
      shadow
      right
      width="700px"
      body-class="d-flex flex-column background-white"
      no-header
      footer-class="background-white footer-sidebar-filters"
      class="sidebar-filters-nets"
    >
    <feather-icon
        class="close-component"
        icon="XIcon"
        size="16"
        @click="open_sidebar = false"
    />
      <b-row class="p-2">
        <b-col>
          <div>
            <h4 class="blueC"><strong>{{$t("search.influencer")}}</strong></h4>
          </div>

          <!-- Idioma -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_language')">
                <span class="search-Globe-1"></span><strong> {{$t("search.influencerLanguage")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_language"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_language"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_language">
              <b-form-select
                v-model="influencer_language"
                :options="$t('instaIdioma')"
              />
            </div>
          </div>

          <!-- Información de contacto -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('info_contact')">
                <span class="search-Information-booklet"></span><strong> {{$t("search.contact")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_info_contact"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_info_contact"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_info_contact">
              <options-filters :options="$t('instaContacto')" @optionsFilter="filterHasContacts" :key="update_info_contact"></options-filters>
            </div>
          </div>
        </b-col>

        <b-col>
          <!-- Filtros rendimiento -->
          <div>
            <h4 class="blueC"><strong>{{$t("search.performance")}}</strong></h4>
          </div>

          <!-- Aumento de seguidores -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('increase_followers_days')">
                  <span class="search-Increasing-Chart1"></span><strong> {{$t("search.increaseFollowers")}}</strong>
                  <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_increase_followers_days"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_increase_followers_days"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_increase_followers_days">
              <b-row>
                <b-col cols="12">
                  <b-form-select
                    v-model="increase_followers_days"
                    :options="$t('days')"
                    class="mb-1"
                />
                </b-col>
                <b-col cols="12" v-if="increase_followers_days">
                  <span class="align-middle mr-1">
                    <strong>
                      {{increase_followers_days}} {{$t("search.daysAlmost")}} {{average_increase_followers}}%
                    </strong>
                  </span>
                </b-col>
                <b-col cols="12" v-if="increase_followers_days">
                  <component
                    :is="vue_slider"
                    v-model="average_increase_followers"
                    :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :max="99"
                    :min="1"
                    :interval="1"
                    @drag-end="changeSlide"
                    :tooltip-formatter="'{value}%'"
                  />
                </b-col>
              </b-row>
            </div>
          </div>

          <!-- Tiempo de transmisión en los últimos 7 días -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('hours_stream')">
                <strong><b-icon class="search-icon" icon="display"/>{{$t("search.timeStreaming")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_hours_stream"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_hours_stream"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_hours_stream">
              <b-form-select
                v-model="hours_stream"
                :options="$t('hoursStream')"
                :placeholder="$t('emptyComboAll')"
              />
            </div>
          </div>

          <!-- Días activos -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('days_active')">
                <strong><b-icon class="search-icon" icon="check-circle"/>{{$t("search.daysActive")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_days_active"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_days_active"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_days_active">
              <b-form-select
                v-model="days_active"
                :options="$t('daysActive')"
                :placeholder="$t('emptyComboAll')"
              />
            </div>
          </div>

          <!-- Juegos -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('period_games')">
                <strong><b-icon class="search-icon" icon="controller"/>{{$t("search.gamesPlayed")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_period_games"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_period_games"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_period_games">
              <b-form-select
                v-model="period_games"
                :options="$t('periodGames')"
                class="mb-2"
              />
              <component
                :is="vue_autosuggest"
                v-if="period_games"
                :suggestions="games"
                :limit="10"
                :input-props="input_games"
                @input="inputGame"
                v-model="game"
                :get-suggestion-value="getGame"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.title }}</span>
                </template>
              </component>
            </div>
          </div>

          <!-- Audience Gender -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('audience_gender')">
                <strong><span class="search-intersex"></span> {{$t("search.audience_gender")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_audience_gender"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_audience_gender"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_audience_gender">
              <div v-for="(gender, index) in $t('instaGen')" :key="index" ref="gender_dropdown">
                <div 
                  :class="`button-gender 
                  ${index === 0 ? 'border-radius-top' : ''} 
                  ${index === $t('instaGen').length -1 ? 'border-radius-bottom' : ''}
                  `"
                  @click="setAudienceGender(gender, index)"
                >
                  {{gender.text}}
                </div>
              </div>
              
              <div v-if="audience_gender !== null ? audience_gender.value !== null : audience_gender !== null">
                <div class="mt-2 white-space-wrap">{{$t('search.more_than')}} {{percentage_gender}}% {{$t('search.of_audience')}}</div>
                <component
                  :is="vue_slider"
                  class="mt-1"
                  v-model="percentage_gender"
                  :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :max="100"
                  :min="55"
                  :interval="5"
                  @drag-end="changeSlide"
                  :tooltip-formatter="'{value}%'"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <template #footer>
        <!-- Button submit -->
        <b-col cols="12" class="button-submit-searcher col-12 mb-3 d-flex flex-wrap">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>

          <b-button class="m-2" variant="outline-secondary" @click="clearFilters()">
            <b-icon icon="trash" class="cursor-pointer"/>
            {{$t('search.reset_filters')}}
          </b-button>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormSelect, BButton, BSidebar, BDropdown, BDropdownHeader, BLink, BIcon, VBTooltip
} from 'bootstrap-vue'
import service from '@/services/search'
import config from '@/services/config'
import { getFiltersUsedLabel, getFormat, numberFormat, separatebycomma } from '@/libs/utils/formats'

export default {
  name: 'FilterTwitch',
  components: {
    OptionsFilters: () => import('@/views/components/button-group/OptionsFilters.vue'),
    BIcon,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BButton,
    BDropdown,
    BDropdownHeader,
    BLink,
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue'),
    FollowersFilter: () => import('@/views/buscador/filters/followers.vue'),
    buttonSubmitFilters: () => import('./buttonSubmitFilters.vue'),
    applyFilters: () => import('./filters/applyFilters.vue'),
    SearchBy: () => import('@/views/components/inputs/SearchBy.vue'),
    viewAvgSlider: () => import('./viewAvgSlider.vue'),
    selectNetworkSearch: () => import('./filters/components/selectNetworkSearch.vue'),
    searchByActions: () => import('@/views/buscador/filters/components/searchByActions.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    initial_filters: {
      type: Object,
      default: () => {}
    },
    networks_avaibles: {
      type: Array,
      default: () => {
        return []
      }
    },
    select_network: {
      type: Object,
      default: () => {
        return {}
      }
    },
    filters_history: {
      type: Object,
      default: () => {}
    },
    main_locations_filter: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      getFormat,
      vue_slider: null,
      vue_autosuggest: null,
      update_info_contact: false,
      update_search_by: false,
      update_location: false,
      update_gender: false,
      update_followers_filter: false,
      open_sidebar: false,
      loading: false,
      formatter: v => `${numberFormat(v)}`,
      formatter_commas: v => `${separatebycomma(v)}`,
      influencer_language: null,
      info_contact: null,
      total_results: 0,
      input_locations: {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      },
      cancel_location: null,
      audience_gender: null,
      increase_followers_days: null,
      average_increase_followers: 50,
      average_viewers: [0, 20000],
      hours_stream: null,
      days_active: null,
      period_games: null,
      games: [],
      input_games: {
        class: 'form-control',
        placeholder: this.$t("search.typeGame")
      },
      game: '',
      game_selected: null,
      cancel_game: null,
      percentage_gender: 55,
      audience_locations: [{}],
      audience_locations_type: [],
      audience_locations_id: [],
      audience_locations_percentage: [],
      text_audience_location: this.$t('search.realty.any'),
      change_audience_location: false,
      text_followers: this.$t('search.realty.any'),
      followers_from: null,
      followers_to: null,
      filters: [
        {
          filter: 'influencer_language',
          text: this.$t("search.influencerLanguage"),
          models: [{name: 'influencer_language', value: null, default: null}],
          value: null,
        },
        {
          filter: 'info_contact',
          text: this.$t("search.contact"),
          models: [{name: 'info_contact', value: null, default: null}],
          update_component: 'update_info_contact',
          value: null,
        },
        {
          filter: 'increase_followers_days',
          text: this.$t("search.increaseFollowers"),
          models: [
            {name: 'increase_followers_days', value: null, default: null},
            {name: 'average_increase_followers', value: null, default: 50}
          ],
          value: null,
        },
        {
          filter: 'hours_stream',
          text: this.$t("search.timeStreaming"),
          models: [{name: 'hours_stream', value: null, default: null},],
          value: null,
        },
        {
          filter: 'days_active',
          text: this.$t("search.daysActive"),
          models: [{name: 'days_active', value: null, default: null},],
          value: null,
        },
        {
          filter: 'period_games',
          text: this.$t("search.gamesPlayed"),
          models: [
            {name: 'period_games', value: null, default: null},
            {name: 'game', value: null, default: ''},
          ],
          value: null,
        },
        {
          filter: 'audience_size',
          text: this.$t("search.audienceSize"),
          models: [
            {name: 'followers_from', value: null, default: null},
            {name: 'followers_to', value: null, default: null},
            {name: 'text_followers', value: null, default: this.$t('search.realty.any')},
          ],
          update_component: 'update_followers_filter',
          value: null,
        },
        {
          filter: 'average_viewers',
          text: this.$t("search.averageViewers"),
          models: [{name: 'average_viewers', value: null, default: [0, 20000]}],
          value: null,
        },
        {
          filter: 'audience_gender',
          text: this.$t("search.audienceGender"),
          models: [
            {name: 'audience_gender', value: null, default: null},
            {name: 'percentage_gender', value: null, default: 55}
          ],
          update_component: 'update_gender',
          value: null,
        },
        {
          filter: 'audience_locations',
          text: this.$t("search.audienceLocation"),
          models: [
            {name: 'audience_locations', value: null, default: [{}]},
            {name: 'text_audience_location', value: null, default: this.$t('search.realty.any')}
          ],
          update_component: 'update_location',
          value: null,
          multiple_values: true,
          syntax: [
            {
              type: 'var',
              name: 'name',
            },
            {
              type: 'string',
              name: ' > ',
            },
            {
              type: 'var',
              name: 'percentage'
            },
            {
              type: 'string',
              name: '%',
            },
          ]
        },
      ],
      searched: true,
      keywords: [],
      where: 'anywhere',
      typing_timer: null,
      cancel_search_ws: null,
      loaded_services_history: true
    }
  },
  created () {
    
  },
  async mounted() {
    this.axios = (await import('axios')).default;
    this.vue_slider = (await import('vue-slider-component')).default;
    this.vue_autosuggest = (await import('vue-autosuggest')).VueAutosuggest
    setTimeout(() => {
      if (this.initial_filters && Object.keys(this.initial_filters).length > 0) {
        this.initialSearch()  
      } else if (Object.keys(this.filters_history).length === 0) {
        this.getCounter()
      }
    }, 200);
    this.searched = true
  },
  computed: {
  },
  methods: {
    updateSelectNetwork(select_network) {
      this.$emit('update_select_network', select_network)
    },
    initialSearch() {
      const filters = {}
      Object.keys(this.initial_filters).forEach((key) => {
        if (key !== 'st') {
          if (key === 'followers') {
            filters['followers_from'] = this.initial_filters[key][0].from
            filters['followers_to'] = this.initial_filters[key][0].to
          } else if (key === 'sort_order') {
            filters[key] = this.initial_filters[key]
          } else if (key === 'sort_type') {
            filters[key] = this.initial_filters[key]
          } else {
            filters[key] = this.initial_filters[key][0].id
          }
        } else {
          filters['st'] = 'tw'
        }
      })

      this.formatApplyFilters(filters)
      this.$emit('clean_selected');
      this.searched = true
      this.$emit('callTwitch', filters, true)
      this.getCounter(filters)
    },
    formatFiltersHistory(filters) {
      this.loaded_services_history = false;
      this.formatApplyFilters(filters, '')
      this.$emit('callTwitch', filters, true)
      this.getCounter(filters)
    },
    formatApplyFilters(filters, name_country = '') {
      Object.keys(filters).forEach(key => {
        if (key === 'followers_from') {
          const filter = this.filters.find(f => f.filter === 'audience_size')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters['followers_to']
          filter.value = `${getFormat(filters[key])}-${getFormat(filters['followers_to'])}`
          this.followers_size = [filters[key], filters['followers_to']]

          if (this.followers_size[0] && this.followers_size[1]) {
            this.text_followers = `${getFormat(this.followers_size[0])}-${getFormat(this.followers_size[1])}`
          } else if (this.followers_from) {
            this.text_followers = `≥${getFormat(this.followers_size[0])}`
          } else if (this.followers_to) {
            this.text_followers = `≤${getFormat(this.followers_size[1])}`
          }
          this.update_followers_filter = !this.update_followers_filter;
        } else if (key === 'hours_streamed') {
          const new_models = [{name: 'hours_stream', value: filters[key]},]
          this.changeFilter('hours_stream', new_models, this.$t('hoursStream').find((item) => item.value === filters[key]).text)
          this.hours_stream = filters[key];
        } else if (key === 'audience_gender_id') {
          this.audience_gender = this.$t('instaGen').find((i) => i.value === filters[key])
          this.percentage_gender = filters['audience_gender_from'];
          const key_index = this.$t('instaGen').findIndex((i) => i.value === this.audience_gender.value)
          this.setAudienceGender(this.audience_gender, key_index)
          this.update_gender = !this.update_gender;
        } else if (key === 'live_viewers_avg_from' || key === 'live_viewers_avg_to') {
          const filter = this.filters.find(f => f.filter === 'average_viewers')
          filter.models[0].value = [
            filters['live_viewers_avg_from'] ? filters['live_viewers_avg_from'] : filter.models[0].default[0],
            filters['live_viewers_avg_to'] ? filters['live_viewers_avg_to'] : filter.models[0].default[1],
          ]
          this.average_viewers[0] = filters['live_viewers_avg_from'] ? filters['live_viewers_avg_from'] : filter.models[0].default[0]
          this.average_viewers[1] = filters['live_viewers_avg_to'] ? filters['live_viewers_avg_to'] : filter.models[0].default[1]
        } else if (key === 'audience_location_id') {
          const filter = this.filters.find(f => f.filter === 'audience_locations')
          this.audience_locations_id = filters['audience_location_id'];
          this.audience_locations_percentage = filters['audience_location_from']
          this.audience_location_type = filters['audience_location_type']
          this.text_audience_location = filters['audience_location_text'] ? filters['audience_location_text'] : this.$t('search.realty.any')
          const country = this.text_audience_location.split(',').slice(0, -1)
          filter.models[0].value = [{id: filter[key], name: name_country.length === 0 ? country.join(', ') : name_country, percentage: filters['audience_location_from'], type: filters['audience_location_type']}]
        } else if (key === 'keywords') {
          filters[key].forEach((i, index) => {
            this.filters.push(
              {
                filter: `search_by_keywords_${index}`,
                text: this.$t(`search.${i.type_keyword}`),
                models: [{name: 'keywords', value: 'fdsa', default: ''}],
                value: i.keyword,
                multiple_values: false
              },
            )            
          })
        } else if (key === 'influencer_language' || key === 'has_contacts') {
          const key_filter = key !== 'has_contacts' ? key : 'info_contact';
          const filter = this.filters.find((f) => f.filter === key_filter)
          filter.models[0].value = filters[key]
          filter.value = filters[key]
          this[key] = filters[key];
        } else if (key === 'active_days_per_week') {
          this.days_active = filters[key];
        } else if (key === 'tw_games_played_game') {
          this.period_games = filters['tw_games_played_period']
          this.game_selected = filters['tw_games_played_game']
          const filter = this.filters.find((f) => f.filter === 'period_games')
          filter.models[0].value = filters['tw_games_played_period']
          filter.models[1].value = filters['tw_games_played_game']
          filter.value = filters['tw_games_played_game_name']
          this.game = filters['tw_games_played_game_name']
        } else if (key === 'followers_growth_to') {
          const filter = this.filters.find(f => f.filter === 'increase_followers_days')
          const period = parseInt(filters['followers_growth_period'])
          filter.models[0].value = period
          filter.models[1].value = filters[key]
          this.increase_followers_days = filters['followers_growth_period'];
          this.average_increase_followers = filters['followers_growth_to']
          filter.value = `${this.$t('days').find((item) => item.value === period).text}, ${filters[key]}%`
        } 

      })
    },
    filterHasContacts(options) {
      this.info_contact = options.value
    },
    addKeywordFilter(new_models, keywords, where = {}) {
      this.keywords = keywords;
      this.getCounter();
      
      if (where.tag_deleted !== null) this.filters = this.filters.filter((i) => i.value !== where.tag_deleted);
      if (where.deleted_all) this.filters = this.filters.filter((i) => !i.filter.includes('search_by_keywords'))
      keywords.forEach((i, index) => {
        if (!this.filters.find(j => j.value === i.keyword)) {
          this.filters.push(
            {
              filter: `search_by_keywords_${index}`,
              text: this.$t(`search.${i.type_keyword}`),
              models: [{name: 'keywords', value: null, default: ''}],
              value: null,
              multiple_values: false
            },
          )
          this.changeFilter(`search_by_keywords_${index}`, new_models, i.keyword);
        }
      })
    },
    getCounterInputHandleIt(prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })
      this.loading = true;
      this.total_results = 0

      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlPhp}/api/v1/buscador/influencers`,
        headers: { 'brandme-key': process.env.VUE_APP_BRANDME_KEY},
        data:{
          ...filters
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.total_results = response.data.data.total ? response.data.data.total : 0
        this.loading = false;
      })
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      if (name.includes('search_by_keywords')) {
        this.$refs.search_by_actions.$refs.search_by_category.keywords_array = this.$refs.search_by_actions.$refs.search_by_category.keywords_array.filter(i => i.keyword !== to_deleted.value)
        this.keywords = this.keywords.filter(i => i.keyword !== to_deleted.value)
      }
      to_deleted.models.forEach((item) => {
        if (!name.includes('search_by_keywords')) this[item.name] = item.default;
        item.value = null;
        if (item.name === 'average_viewers') this.average_viewers = [0, 20000]
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      to_deleted.value = null;
      this.closeCollapse(to_deleted.filter)
      if (get_counter) this.getCounter();
      this.searched = true
      if (name.includes('search_by_keywords')) this.filters = this.filters.filter(i => i.filter !== name)

    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    closeCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.contains('d-none') ? '' : this.$refs[`collapse_filters_${id}`].classList.add('d-none');
        this.$refs[`chev_up_${id}`].classList.add('d-none');
        this.$refs[`chev_down_${id}`].classList.contains('d-none') ? this.$refs[`chev_down_${id}`].classList.remove('d-none') : '';
      }
    },
    clearFilters(loading_results = true, send_service = true) {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })
      if (send_service) {
        this.sendFilters(loading_results);
        this.getCounter();
      }
      this.open_sidebar = false;
      this.searched = true
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    setAudienceGender(gender, index) {
      this.audience_gender = gender;
      this.$refs.gender_dropdown.map((item, i) => {
        if (i === index) item.classList.add('background-clicked-form');
        else item.classList.remove('background-clicked-form');
      });
    },
    getText() {
      if (this.loading) return this.$t("search.searching")
      if (this.total_results > 0 && this.searched) return `${getFormat(this.total_results)} ${this.$t("search.results")}`
      if (!this.searched) return `${getFormat(this.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
    getRangeViewers() {
      if (this.average_viewers[0] !== 0 && this.average_viewers[1] !== 20000) {
        return {
          'from': this.average_viewers[0],
          'to': this.average_viewers[1]
        }
      } else if (this.average_viewers[0] > 0 && this.average_viewers[1] === 20000) {
        return {
          'from': this.average_viewers[0],
          'to': null
        }
      } else if (this.average_viewers[0] === 0 && this.average_viewers[1] < 20000) {
        return {
          'from': null,
          'to': this.average_viewers[1]
        }
      }

      return {
        'from': null,
        'to': null,
      }
    },
    getAvgRangeViewers() {
      let sentence = '';
      if (this.average_viewers[1] === 20000) sentence = `≥ ${getFormat(this.average_viewers[0])}`
      else if (this.average_viewers[0] === 0)  sentence = `≤ ${getFormat(this.average_viewers[1])}`
      else sentence = `${getFormat(this.average_viewers[0])} - ${getFormat(this.average_viewers[1])}`;
      return sentence;
    },
    getCounter(prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })

      this.loading = true;
      this.total_results = 0
      service.getResults(filters)
        .then(response => {
          if (Object.keys(this.filters_history).length > 0) this.loaded_services_history = true;
          this.total_results = response.data.total
          this.loading = false;
        }).catch(() => {
          this.total_results = 0
          this.loading = false;
        })
    },
    addAudienceLocation() {
      this.audience_locations.push({})
    },
    removeAudienceLocation(location, index, force_remove = false, from_filters = false) {
      const self = this
      setTimeout(function() {
        const is_show = self.$refs.filter_audience_location.$el.querySelector('ul').classList.contains('show')
        if (!from_filters && !is_show && force_remove) self.$refs.filter_audience_location.$el.querySelector('button').click()
      }, 5)

      if (force_remove) this.audience_locations.splice(index, 1)
      else this.audience_locations[index] = {}

      if (!location) return

      if (this.audience_locations.length === 0) {
        this.deleteFilter('audience_locations')
        this.text_audience_location = this.$t('search.realty.any')
      } else {
        let counter_empty = 0;
        const location_length = this.audience_locations.filter((item) => item.id).length;
        this.audience_locations.map((item) => {
          if (item.id) {
            const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
            this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
          } else {
            counter_empty += 1;
          }
          if (this.audience_locations.length === counter_empty) {
            this.deleteFilter('audience_locations')
            this.text_audience_location = this.$t('search.realty.any');
          }
        });

        const new_models = [{name: 'audience_locations', value: this.audience_locations}]
        this.changeFilter('audience_locations', new_models, this.text_audience_location)
      }
      this.update_location = !this.update_location
      this.getCounter()
    },
    changeLocationAudience(data) {
      const index = data.key
      this.audience_locations[index].id = data.location.id
      this.audience_locations[index].type = data.location.type
      this.audience_locations[index].name = data.location.name
      this.audience_locations[index].percentage = data.percentage

      const location_length = this.audience_locations.filter((item) => item.id).length;

      this.audience_locations.map((item) => {
        if (item.id) {
          const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
          this.text_audience_location = `${item.name}, ≥${item.percentage}% ${additional_text}`;
        }
      });

      const new_models = [{name: 'audience_locations', value: this.audience_locations}]
      this.changeFilter('audience_locations', new_models, this.text_audience_location)

      this.getCounter()
    },
    changeFollowers(data) {
      this.followers_from = data.from
      this.followers_to = data.to
      let value_model = data.from + data.to
      if (this.followers_from && this.followers_to) {
        this.text_followers = `${getFormat(this.followers_from)}-${getFormat(this.followers_to)}`
      } else if (this.followers_from) {
        this.text_followers = `≥${getFormat(this.followers_from)}`
      } else if (this.followers_to) {
        this.text_followers = `≤${getFormat(this.followers_to)}`
      } else {
        value_model = null
        this.text_followers = this.$t('search.realty.any')
      }
      const new_models = [
        {name: 'followers_from', value: value_model},
        {name: 'followers_to', value: this.followers_to},
      ]
      this.changeFilter('audience_size', new_models, this.text_followers)
      this.getCounter()
    },
    formatAudienceLocations() {
      this.audience_locations_id = []
      this.audience_locations_type = []
      this.audience_locations_percentage = []
      if (this.audience_locations.length > 0) {
        this.audience_locations.forEach(location => {
          if (location.id) {
            this.audience_locations_id.push(location.id)
            this.audience_locations_type.push(location.type)
            this.audience_locations_percentage.push(location.percentage)
          }
        })
      }
    },
    getFilters() {
      const finalFilters = {}
      const viewers = this.getRangeViewers();
      this.formatAudienceLocations()

      const data = {
        hash: localStorage.getItem('accessToken'),
        st: 'tw',
        keywords: this.keywords.length !== 0 ? this.keywords : null,
        where: this.where === 'anywhere' ? null : this.where,
        followers_from: this.followers_from,
        followers_to: this.followers_to,
        influencer_language: this.influencer_language,
        has_contacts: this.info_contact,
        audience_location_id: this.audience_locations_id.length > 0 ? this.audience_locations_id : null,
        audience_location_type: this.audience_locations_type.length > 0 ? this.audience_locations_type : null,
        audience_location_from: this.audience_locations_percentage.length > 0 ? this.audience_locations_percentage : null,
        audience_location_text: this.text_audience_location !== this.$t('search.realty.any') ? this.text_audience_location : null,
        audience_gender_id: this.audience_gender ? this.audience_gender.value : null,
        audience_gender_from: this.audience_gender ? this.percentage_gender : null,
        followers_growth_period: this.increase_followers_days ? this.increase_followers_days : null,
        followers_growth_to: this.increase_followers_days ? this.average_increase_followers : null,
        live_viewers_avg_from: viewers.from,
        live_viewers_avg_to: viewers.to,
        hours_streamed: this.hours_stream,
        active_days_per_week: this.days_active,
        tw_games_played_period: this.game_selected ? this.period_games : null,
        tw_games_played_game: this.game_selected ? this.game_selected.id : null,
        tw_games_played_game_name: this.game ? this.game : null,
        total_results: this.total_results,
        filters_label_used: getFiltersUsedLabel(this.filters)
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          finalFilters[dataKey] = data[dataKey]
        }
      }

      return finalFilters
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    inputGame(text) {
      this.games = [];
      if (text !== '') {
        const CancelToken = this.axios.CancelToken;

        if (this.cancel_location) this.cancel_location()

        const self = this;
        this.axios({
          method: 'post',
          url: `${config.urlPhp}/api/v1/juegos`,
          data:{
            game: text
          },
          headers: {
            'brandme-key': process.env.VUE_APP_BRANDME_KEY,
          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            self.cancel_location = c;
          })
        })
          .then(result => {
            const filteredData = []
            result.data.games.forEach(game => {
              filteredData.push(game.basic)
            })
            self.games = [{data: filteredData}]
          })
          .catch(error => {
            if (this.axios.isCancel(error)) {
              console.log('');
            }
            return []
          });
      }
    },
    getGame(suggestion) {
      this.game = suggestion.item.title
      this.game_selected = suggestion.item
      this.getCounter()
    },
    sendFilters(loading_results = true) {
      this.searched = true
      const finalFilters = this.getFilters()
      this.$emit('callTwitch', finalFilters, loading_results)
      this.$emit('clean_selected');
    },
    closeSideBar() {
      this.open_sidebar = false
    },
    changeSlideComponent(identifier, new_range) {
      this[identifier] = new_range
      this.getCounter()

      if (identifier === 'average_viewers') {
        const value_filter = this.getAvgRangeViewers();
        const new_models = [{name: 'average_viewers', value: value_filter !== '≥ 0' ? new_range : null}]
        if (value_filter !== '≥ 0') {
          this.changeFilter('average_viewers', new_models, value_filter)
        } else {
          this.changeFilter('average_viewers', new_models, value_filter)
        }
      }
    },
    changeSlide() {
      this.getCounter()
    },
    getClasses(value) {
      if (value === 0 || value === 20000) return 'value-inactive'
      return 'value-active'
    },
    getProgressStyle(style) {
      if (this.average_viewers[0] !== 0 && this.average_viewers[1] !== 20000) {
        return {
          'left': style.left,
          'width': style.width,
          'background-color': '#7367f0'
        }
      } else if (this.average_viewers[0] === 0 && this.average_viewers[1] !== 20000) {
        const width = 100 - parseFloat(style.width)
        return {
          'left': style.width,
          'width': `${width}%`,
          'background-color': '#7367f0'
        }
      }

      const left = this.average_viewers[1] === 20000 ? 0 : style.width;
      const width = this.average_viewers[0] === 0 ? style.width : style.left;
      return {
        left,
        width,
        'background-color': '#7367f0'
      }
    },
    getLabelViewers() {
      if (this.average_viewers[0] === 0 && this.average_viewers[1] === 20000) return this.$t("search.anyQuantity")
      if (this.average_viewers[0] !== 0 && this.average_viewers[1] !== 20000) return `${this.$t("search.between")} ${numberFormat(this.average_viewers[0])} ${this.$t("search.and")} ${numberFormat(this.average_viewers[1])}`
      if (this.average_viewers[0] > 0) return `${this.$t("search.lessThan")} ${numberFormat(this.average_viewers[0])}`
      if (this.average_viewers[1] < 20000) return `${this.$t("search.greaterThan")} ${numberFormat(this.average_viewers[1])}`

      return this.$t("search.anyQuantity")
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)

      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)

        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    updateModel(data) {
      if (data.name_emit) {
        this[data.name_emit](...data.params)
      }
    }
  },
  watch: {
    loading(val) {
      this.$emit('change_loading', val);
    },
    filters_history(val, old_val) {
      this.clearFilters(true, false);
      if (val && Object.keys(val).length > 0 && (JSON.stringify(val) !== JSON.stringify(old_val))) {
        this.formatFiltersHistory(val, '')
      }
    },
    initial_filters(val) {
      if (Object.keys(val).length > 0) this.initialSearch()
    },
    '$i18n.locale'() {
      this.input_locations = {
        class: 'form-control',
        placeholder: this.$t("search.typeLocation")
      }

      this.input_games = {
        class: 'form-control',
        placeholder: this.$t("search.typeGame")
      }
    },
    average_viewers(val) {
      const value_filter = this.getAvgRangeViewers();
      const new_models = [{name: 'average_viewers', value: value_filter !== '≥ 0' ? val : null}]
      if (value_filter !== '≥ 0') {
        this.changeFilter('average_viewers', new_models, value_filter)
      } else {
        this.changeFilter('average_viewers', new_models, value_filter)
      }
    },
    influencer_language(val) {
      this.update_filters_view = !this.update_filters_view;

      const new_models = [{name: 'influencer_language', value: this.$t('instaIdioma').find((item) => item.value === val).value}]
      this.changeFilter('influencer_language', new_models, this.$t('instaIdioma').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    info_contact(val) {
      this.update_filters_view = !this.update_filters_view;
      const new_models = [{name: 'info_contact', value: this.$t('instaContacto').find((item) => item.value === val).value}]
      this.changeFilter('info_contact', new_models, this.$t('instaContacto').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    audience_gender(val) {
      const new_models = [{name: 'audience_gender', value: val ? val.value : null}]
      if (val && val.value === null) {
        new_models.push({name: 'percentage_gender', value: 55})
        this.percentage_gender = 55;
      }
      this.changeFilter('audience_gender', new_models, val ? `${val.text}, ≥${this.percentage_gender}%` : null)
      if (this.loaded_services_history) this.getCounter()
    },
    percentage_gender(val) {
      const new_models = [{name: 'percentage_gender', value: val}]
      this.changeFilter('audience_gender', new_models, `${this.audience_gender.text}, ≥${val}%`)
    },
    increase_followers_days(val) {
      const new_models = [
        {name: 'increase_followers_days', value: this.$t('days').find((item) => item.value === val).value},
        {name: 'average_increase_followers', value: this.average_increase_followers}
      ]
      this.changeFilter('increase_followers_days', new_models, `${this.$t('days').find((item) => item.value === val).text}, ≥${this.average_increase_followers}`)

      if (this.increase_followers_days === null) this.average_increase_followers = 50
      if (this.loaded_services_history) this.getCounter()
    },
    average_increase_followers(val) {
      this.changeFilter('increase_followers_days', [], `${this.$t('days').find((item) => item.value === this.increase_followers_days).text}, ≥${val}`)
    },
    set_viewers() {
      if (this.set_viewers === 'hide') this.average_viewers = [50, 20000]
      if (this.loaded_services_history) this.getCounter()
    },
    hours_stream(val) {
      const new_models = [{name: 'hours_stream', value: ''},]
      this.changeFilter('hours_stream', new_models, this.$t('hoursStream').find((item) => item.value === val).text)

      if (this.loaded_services_history) this.getCounter()
    },
    days_active(val) {
      const new_models = [{name: 'days_active', value: this.$t('daysActive').find((item) => item.value === val).value},]
      this.changeFilter('days_active', new_models, this.$t('daysActive').find((item) => item.value === val).text)

      if (this.loaded_services_history) this.getCounter()
    },
    period_games() {      
      if (this.period_games === null) {
        this.game_selected = null;
        this.game = '';
      }

      if (this.game_selected && this.loaded_services_history) this.getCounter()
    },
    game(val) {
      const new_models = [
        {name: 'period_games', value: this.$t('periodGames').find((item) => item.value === this.period_games).value},
        {name: 'game', value: val}
      ]
      this.changeFilter('period_games', new_models, `${this.$t('periodGames').find((item) => item.value === this.period_games).text}, ${val}`)
    }
  }
}
</script>

<style type="text/css">
  .value-active {
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: none;

    width: 100%;
    height: 100%;
    background-color: white;
    transition: all .3s;
    border: 2px solid #7367f0;
  }
  .value-inactive {
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: none;
    width: 100%;
    height: 100%;
    background-color: gray;
    transition: all .3s;
    border: 2px solid white;
  }
   .filter-performance {
     border-top: #ebebeb solid 1px;
   }
</style>
<style lang="scss">
#card-twitch-filters > .card-body {
  padding: 8px 20px 8px 8px;
  display: flex;
  align-items: center;
  @media(max-width: 991px) {
    flex-wrap: wrap;
    padding: 8px;
  }
}
.select-network-search {
  width: auto;
  @media(max-width: 991px) {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
.p-custom {
  padding: 0 1.28rem 0.5rem 1.28rem;
}
.mr-03 {
  margin-right: 0.3em;
}
.container-more-filters {
  @media(max-width: 991px) {
    width: 100%;
    margin-top: 1em;
  }
  @media(min-width: 992px) {
    margin-left: 1em;
  }
}
</style>