<template>
  <div class="d-flex justify-content-center flex-wrap">
    <b-row class="row-main-searcher mt-1 w-100">
      <b-col
        md="12"
        class="p-0"
        :class="{'mb-4': !loading_results}"
      >
      
        <b-row>
          <b-col class="col-12">
            <h1 class="mb-2" v-if="!loading_results">{{$t('filters.startSearch')}}</h1>
            <b-icon icon="arrow-left" v-else @click="goBackAction()" class="arrow-left-go-back-clean"></b-icon>
            <instagram-filter
              :initial_filters="initial_filters"
              :filters_history="filters_history"
              :networks_avaibles="networks_avaibles"
              :select_network="select_network"
              @callInstagram="searchFilters"
              :main_locations_filter="main_locations_filter"
              @clean_selected="checked_influencer = []"
              @update_select_network="updateSelectNetwork"
              @change_loading="changeLoading"
              v-if="select_network.value === 'instagram'"
              ref="filter_network"
            ></instagram-filter>

            <filtroYoutube
            :initial_filters="initial_filters"
            :filters_history="filters_history"
            :networks_avaibles="networks_avaibles"
            :select_network="select_network"
            :main_locations_filter="main_locations_filter"
            @callYoutube="searchFilters"
            @clean_selected="checked_influencer = []"
            @update_select_network="updateSelectNetwork"
            @change_loading="changeLoading"
            v-if="select_network.value === 'youtube'"
            ref="filter_network"
            
            ></filtroYoutube>

            <filtroTiktok
              :initial_filters="initial_filters"
              :networks_avaibles="networks_avaibles"
              :filters_history="filters_history"
              :select_network="select_network"
              :main_locations_filter="main_locations_filter"
              @callTiktok="searchFilters"
              @clean_selected="checked_influencer = []"
              @update_select_network="updateSelectNetwork"
              @change_loading="changeLoading"
              v-if="select_network.value === 'tiktok'"
              ref="filter_network"
            ></filtroTiktok>

            <filtroTwitter
              :initial_filters="initial_filters"
              :networks_avaibles="networks_avaibles"
              :select_network="select_network"
              :filters_history="filters_history"
              :main_locations_filter="main_locations_filter"
              @callTwitter="searchFilters"
              @update_select_network="updateSelectNetwork"
              @clean_selected="checked_influencer = []"
              @change_loading="changeLoading"
              v-if="select_network.value === 'twitter'"
              ref="filter_network"
            ></filtroTwitter>

            <filtroTwitch
              :initial_filters="initial_filters"
              :filters_history="filters_history"
              :networks_avaibles="networks_avaibles"
              :select_network="select_network"
              :main_locations_filter="main_locations_filter"
              @callTwitch="searchFilters"
              @update_select_network="updateSelectNetwork"
              @clean_selected="checked_influencer = []"
              @change_loading="changeLoading"
              v-if="select_network.value === 'twitch'"
              ref="filter_network"
            ></filtroTwitch>
          </b-col>
        </b-row>

      </b-col>

      <div v-for="i in 2" :key="i" class="w-100">
        <history-filters-search           
          :is_favorite="i === 1"
          :loading_service="loading_service"
          v-if="loading_results === false" 
          class="mb-4"
          @set_filters="setFiltersRecentSearches"
        ></history-filters-search>
      </div>

      <dashboard :is_in_searcher="true" v-if="loading_results === false" ref="dashboard_outstanding" class="position-relative dashboard-analyzer-container"></dashboard>

    </b-row>
    <b-row class="w-100">
      <b-col
        cols="12"
        class="pl-0 pr-0 mt-2"
      >
        <div class="actions-top-header-table" v-if="loading_results">
          <div class="d-flex">
            <b-button-group class="body-button-center mr-1">
              <b-button 
                :class="change_view_card ? 'button-outline-header-grid-no-active' : 'button-outline-header-grid-active'" 
                :variant="change_view_card ? 'button-outline-header-grid-no-active' : 'button-outline-header-grid-active'" 
                @click="change_view_card = false"
              >
                <b-icon icon="grid-fill" font-scale="1.5" variant="dark"></b-icon>
              </b-button>
              <b-button 
                :class="change_view_card ? 'button-outline-header-grid-active ml-05' : 'button-outline-header-grid-no-active ml-05'" 
                :variant="change_view_card ? 'button-outline-header-grid-active' : 'button-outline-header-grid-no-active'" 
                @click="change_view_card = true"
              >
                <b-icon icon="list-ol" font-scale="1.5" variant="dark"></b-icon>
              </b-button>
            </b-button-group>

            <component :is="v_select" :clearable="false" :options="select_sort_options" v-model="selected_sort" class="select-sort-options-searcher">
              <template #selected-option="{ label }">
                <div>
                  {{ label }} <b-img :src="require('@/assets/images/svg/sort.svg')" class="sort-icon-svg"></b-img>
                </div>
              </template>
              <template #option="{ label }"><div class="avenir-medium">{{ label }} </div></template>
            </component>
          </div>
          <div class="d-flex">
            <b-dropdown 
              no-flip
              no-caret 
              right
              variant="link"
              toggle-class="text-decoration-none"
              class="dropdown-hidding-columns-searcher"
            >
              <template #button-content> 
                <b-button variant="button-outline-header" class="button-outline-header"><feather-icon icon="SettingsIcon" size="18"></feather-icon></b-button>
              </template>
              <b-dropdown-header class="p-05">
                <span class="mb-1 d-block">{{ $t('search.visibilityColumns')}} </span>
                <b-form-group class="m-0">
                  <b-form-checkbox
                    v-for="(option) in $t(`${fields}`).filter(i => i.is_showing !== undefined)"
                    v-model="columns_table_selected"
                    :key="option.key"
                    :value="option.key"
                    class="avenir-medium d-flex align-items-center"
                  >
                    {{ option.label }}
                  </b-form-checkbox>
                </b-form-group>
              </b-dropdown-header>
            </b-dropdown>
            <b-button variant="button-outline-header" class="button-outline-header" @click="exportSelectedInfluencers"><b-img class="img-icon" :src="require('@/assets/images/svg/file_csv.svg')"></b-img></b-button>
            <b-button variant="button-outline-header" class="button-outline-header avenir-medium d-flex align-items-center" @click="saveSearch" :key="update_button_favorite">
              <feather-icon icon="HeartIcon" size="18"></feather-icon> 
              <span class="d-none d-sm-block ml-05">
                {{ (Object.keys(filters_history).length === 0) || ((Object.keys(filters_history).length > 0) && !filters_history.is_favorite) ? $t('search.saveSearch') : $t('search.eliminateSearch')}} 
              </span>
            </b-button>
            <b-button variant="button-outline-header" class="button-outline-header" @click="sidebar_searches_saved = true; sidebar_searches_saved_update = !sidebar_searches_saved_update"><b-icon icon="folder2-open" size="18"></b-icon></b-button>
          </div>

          <div class="d-flex justify-content-between w-100 align-items-center mt-1" v-if="!change_view_card && checked_influencer.length > 0">
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="selected_all_inner"></b-form-checkbox>
              <dropdown-campaigns 
                :campaigns="options" 
                :disabled="checked_influencer.length === 0" 
                :influencers="getFormatInfluencerToAdd()" 
                :multiple_added="true"
                class="ml-2"
                :text_button="'search.add_to_campaign'"
              />
              <dropdown-lists
                :lists="lists"
                :disabled="checked_influencer.length === 0" 
                :influencers="getFormatInfluencerToAdd()"
                :multiple_added="true"
                class="ml-1"
                :text_button="'search.add_to_list'"
              />
            </div>
            <div v-if="$refs.filter_network.total_results">{{ checked_influencer.length }} de {{getFormat($refs.filter_network.total_results)}} seleccionado</div>
          </div>
        </div>
        <div class="position-relative overflow-hidden-main-container-results-table">
          <div v-if="change_view_card && loading_results" class="table-wrapper">
            <div class="top-19 container-free-results-absolute" v-if="is_free">
              <div class="w-100 h-100 position-relative w-max-content">
                <box-search-free :total_count="$refs.filter_network.total_results ? getFormat($refs.filter_network.total_results) : '0'"></box-search-free>
              </div>
            </div>
            <b-table
              :fields="fields_for_table"
              :items="infoTable"
              id="table-searcher"
              :class="getClassForTable()"
              @row-clicked="rowTableClick"
              @row-hovered="rowHovered"
              @row-unhovered="rowUnhovered"
              head-variant="light"
              ref="table_searcher"
              :key="update_table_results"
            >
              <!-- :class="{'table-searcher-padding': checked_influencer.length === 0}" -->
            <template #head(id)>
              <b-form-checkbox v-model="selected_all"></b-form-checkbox>
            </template>
            <template #head(followers)="data">
              <div @click="changeOrder('fc')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === 'fc'}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === 'fc'"></b-icon>
              </div>  
            </template>
            <template #head(audience)="data">
              <div @click="changeOrder('rfc')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === 'rfc'}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === 'rfc'"></b-icon>
              </div>  
            </template>
            <template #head(tp)="data">
              <div @click="changeOrder('er')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === 'er'}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === 'er'"></b-icon>
              </div>  
            </template>
            <template #head(average_views)="data">
              <div @click="changeOrder(select_network.src === 'yt' ? 'pv_a' : select_network.src === 'tw' ? 'live_viewers_avg' : 'views_avg')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === (select_network.src === 'yt' ? 'pv_a' : select_network.src === 'tw' ? 'live_viewers_avg' : 'views_avg')}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === (select_network.src === 'yt' ? 'pv_a' : select_network.src === 'tw' ? 'live_viewers_avg' : 'views_avg')"></b-icon>
              </div>  
            </template>
            <template #head(likes)="data">
              <div @click="changeOrder('likes')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === 'likes'}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === 'likes'"></b-icon>
              </div>  
            </template>
            <template #head(tweets)="data">
              <div @click="changeOrder('twts')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === 'twts'}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === 'twts'"></b-icon>
              </div>  
            </template>
            <template #head(retweets)="data">
              <div @click="changeOrder('rtwts')" class="text-capitalize d-flex align-items-center" :class="{'font-bold-black': sort_options.type === 'rtwts'}">
                {{ data.label }}
                <b-icon :icon="sort_options.order === 'desc' ? 'arrow-down' : 'arrow-up'" v-if="sort_options.type === 'rtwts'"></b-icon>
              </div>  
            </template>
            <template #head(email)="data">
              <div class="w-30">{{ data.label }} </div>
            </template>
              <template v-slot:thead-top>
                <b-tr class="d-none container-add-list-campaigns-table-template" id="tr-actions-select">
                  <b-th colspan="12" class="w-100 p-0 px-25">
                    <div class="d-flex justify-content-between w-100 align-items-center h-56">
                      <div class="d-flex align-items-center">
                        <b-form-checkbox v-model="selected_all_inner"></b-form-checkbox>
                        <dropdown-campaigns 
                          :campaigns="options" 
                          :disabled="checked_influencer.length === 0" 
                          :influencers="getFormatInfluencerToAdd()" 
                          :multiple_added="true"
                          class="ml-2"
                          :text_button="'search.add_to_campaign'"
                        />
                        <dropdown-lists
                          :lists="lists"
                          :disabled="checked_influencer.length === 0" 
                          :influencers="getFormatInfluencerToAdd()"
                          :multiple_added="true"
                          class="ml-1"
                          :text_button="'search.add_to_list'"
                        />
                      </div>
                      <div v-if="$refs.filter_network.total_results">{{ checked_influencer.length }} de {{getFormat($refs.filter_network.total_results)}} seleccionado</div>
                    </div>
                  </b-th>
                </b-tr>                  
              </template>                      

              <template #cell(id)="data">
                <div class="d-flex justify-content-between main-container-cell-id position-absolute">
                  <b-form-checkbox
                    class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
                    v-model="checked_influencer"
                    :value="data.item.id"
                    :ref="`checkbox_table_${data.item.id}`"
                  >
                  </b-form-checkbox>
                  <span class="id-table-searcher avenir-medium" :ref="`id_table_${data.item.id}`">{{data.item.id}}</span>
                </div>
      
              </template>
              <template #cell(account)="data">
                <div>
                  <b-media vertical-align="center">
                    <template #aside>
                      <div style="position: relative">
                        <b-avatar
                          size="40px"
                          :src="data.item.username.avatar"
                          variant="light-primary"
                        />
                      </div>
                    </template>
                    <div class="d-flex flex-column justify-content-center media-container-avatar">
                      <span class="d-flex align-items-center full-name-influencer-hover" @click.stop="getProfileUrl(data.item.username.name)">
                        {{ data.item.username.name.fullname }} <image-verified v-if="data.item.username.name.is_verified" class="ml-05"/>
                      </span>
                      <span v-if="data.item.username.name.username" class="avenir-light d-block text-nowrap mb-0 text-muted-username">
                        @{{ data.item.username.name.username }}
                      </span>
                    </div>             
                  </b-media>
                </div>
              </template>
              <template #cell(followers)="data">
                <p
                  class="avenir-medium d-block text-nowrap mb-0"
                >
                  {{ data.item.followers ? getFormat(data.item.followers) : '0' }}
                </p>
              </template>
              <template #cell(average_views)="data">
                <p
                  class="avenir-medium d-block text-nowrap mb-0"
                >
                  {{ data.item.average_views ? getFormat(data.item.average_views) : '0'}}
                </p>
              </template>
              <template #cell(likes)="data">
                <p class="avenir-medium d-block text-nowrap mb-0"> {{ data.item.likes ? getFormat(data.item.likes) : '0' }}</p>
              </template>
              <template #cell(views_30d)="data">
                <p class="avenir-medium d-block text-nowrap mb-0">
                  {{ data.item.views ? getFormat(data.item.views) : '0'}}
                </p>
              </template>
              <template #cell(audience)="data">
                <p class="avenir-medium d-block text-nowrap mb-0">
                  {{ data.item.audience ? getFormat(data.item.audience) : '0'}}
                </p>
              </template>
              <template #cell(tp)="data">
                <p class="avenir-medium d-block text-nowrap mb-0">
                  {{ data.item.tp }} %
                </p>
              </template>
              <template #cell(mentions)="data">              
                <b-avatar-group size="22px" v-if="data.item.mentions && data.item.mentions.avatars.length > 0">
                  <b-avatar v-for="(ava, index) in data.item.mentions.avatars" :key="index" :src="ava"></b-avatar>
                  <b-avatar variant="light" v-if="data.item.mentions.total > 0">
                    <span class="text-dark font-size-9">+{{ data.item.mentions.total }}</span>
                  </b-avatar>
                </b-avatar-group>
                <span v-else class="avenir-medium">{{ $t('search.noMentions') }}</span>
              </template>
              <template #cell(email)="data">
                <div class="">
                  <feather-icon icon="CheckIcon" v-if="data.item.email"></feather-icon>
                </div>
              </template>
              <template #cell(platforms)="data">
                <div class="">
                  <b-avatar-group size="16px" v-if="data.item.networks.length > 0">
                    <b-avatar class="size-16-icons" v-for="(value, index) in data.item.networks" :key="index">
                      <b-img :src="getIconWhite(value)" :class="`gray-icons-colors icon-networks-${data.item.id}`" fluid></b-img>
                    </b-avatar>
                  </b-avatar-group>
                </div>
              </template>
              <template #cell(categories)="data">
                <div class="h-16 position-relative" v-if="data.item.categories && data.item.categories.all_categories.length > 0 && data.item.id" >
                  <span 
                    class="avatar-categories"
                    :id="`categories-list-unique-${data.item.id}`"
                    v-for="(i) in data.item.categories.all_categories.length" :key="i"
                    :style="getStyle(i)"
                    @mouseover="updatePopover(data.item.categories.all_categories, `categories-list-unique-${data.item.id}`)"
                  >
                  {{ data.item.categories.first_category }}
                 </span>
                </div>
              </template>
              <template #cell(tweets)="data">
                <p
                  class="font-weight-bold d-block text-nowrap mb-0"
                >
                  {{ data.item.tweets ? numberFormat(data.item.tweets) : 0 }}
                </p>
              </template>
              <template #cell(retweets)="data">
                <p
                  class="font-weight-bold d-block text-nowrap mb-0"
                >
                  {{ data.item.retweets ? numberFormat(data.item.retweets) : 0 }}
                </p>
              </template>
            </b-table>
          </div>

          <div v-else-if="loading_results" class="row mt-2 position-relative">
            <div v-for="(influencer, index) in infoTable" :key="index" :class="`${!is_free ? 'col-12 col-lg-6 col-xl-3' : 'col-12 col-md-8 mx-auto p-0'}`">
              <b-card class="card-view-searcher">
                <b-form-checkbox
                  class="d-none float-right checkbox-card-view"
                  v-model="checked_influencer"
                  :value="index + 1"
                  :ref="`checkbox_card_${index + 1}`"
                >
                </b-form-checkbox>
                <b-avatar class="card-image-influencer col-4 p-0" rounded="left" :src="influencer.username.avatar" />
                <b-card-text class="col-8 py-1">
                  <div class="w-100">
                    <b-link class="username-card-view cursor-pointer" @click="getProfileUrl(influencer.username.name)" target="_blank">
                      <h4>{{influencer.username.name.username}} <image-verified v-if="influencer.username.name.is_verified"/></h4>
                      <span class="text-muted-username" v-if="influencer.username.name.username">
                        {{influencer.username.name.fullname}}  
                      </span>
                    </b-link>
                    
                    <div class="mt-1 d-flex justify-content-between flex-wrap font-size-13">
                      <div>
                        <span class="follow-er-view-card">{{$t('campaigns.followers')}}</span>
                        <span class="d-block">{{getFormat(influencer.followers)}}</span>
                      </div>
                      <div>
                        <span class="follow-er-view-card">{{influencer.tp && select_network.value !== 'twitch' ? 'ER' : $t('twitch.averageViewsLive')}}</span>
                        <span class="d-block">{{influencer.tp && select_network.value !== 'twitch' ? `${numberFormat(influencer.tp, 2)} %` : getFormat(influencer.average_views)}}</span>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </div>
            <div class="container-free-results-absolute second-potition" v-if="is_free">
              <div class="w-100 h-100 position-relative w-max-content">
                <box-search-free 
                  :total_count="$refs.filter_network.total_results ? getFormat($refs.filter_network.total_results) : '0'"
                  :second_position="true"
                ></box-search-free>
              </div>
            </div>
          </div>

          <b-popover v-if="popover_target" :target="popover_target" triggers="hover" placement="left" custom-class="popover-categories" :key="update_popover">
            <div v-html="popover_content" class="text-white"></div>
          </b-popover>
    
          <div class="container-loader-searcher" v-if="loader_results">
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        style="text-align: center"
        v-if="!is_free && loading_results"
      >
        <ul
          role="menubar"
          aria-disabled="false"
          aria-label="Pagination"
          class="pagination my-0 b-pagination pagination-sm justify-content-center"
        >
          <li role="presentation" :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" aria-hidden="true" @click="first()" >
            <span
              role="menuitem"
              type="button"
              aria-label="Go to first page"
              class="page-link"
              ><span class="text-dark">&lt;&lt;</span></span
            >
          </li>
          <li role="presentation" :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" aria-hidden="true" @click="prev()" >
            <span
              role="menuitem"
              type="button"
              aria-label="Go to previous page"
              class="page-link"
              ><span class="text-dark">&lt;</span></span
            >
          </li>
          <li role="presentation" class="page-item active">
            <button
              role="menuitemradio"
              type="button"
              aria-label="Go to page 1"
              aria-checked="true"
              aria-posinset="1"
              aria-setsize="43789"
              tabindex="0"
              class="page-link"
            >
              <b>{{currentPage}}</b>
            </button>
          </li>
          <li role="presentation" :class="`page-item ${!nextPage ? 'disabled' : ''}`" aria-hidden="true" @click="next()">
            <button
              role="menuitem"
              type="button"
              tabindex="-1"
              aria-label="Go to next page"
              class="page-link"
            >
              <span class="text-dark">&gt;</span>
            </button>
          </li>
          <li role="presentation" :class="`page-item ${!nextPage ? 'disabled' : ''}`" aria-hidden="true" @click="last()" >
            <button
              role="menuitem"
              type="button"
              tabindex="-1"
              aria-label="Go to last page"
              class="page-link"
            >
              <span class="text-dark">&gt;&gt;</span>
            </button>
          </li>
        </ul>
      </b-col>
      <sidebar-search-new
        :open_sidebar="open_sidebar" 
        :key="update_sidebar" 
        :data="data_sidebar"
        :network="select_network.value"
        class="open-sidebar-search"
        v-if="open_sidebar && select_network.value === 'instagram'"
        @add_to_campaign="addInfuencerButton"
        @get_profile_url="getProfileUrl"
      >
      </sidebar-search-new>
      <sidebar-search 
        :open_sidebar="open_sidebar" 
        :key="update_sidebar + 1" 
        :data="data_sidebar"
        :network="select_network.src"
        class="open-sidebar-search"
        v-else-if="open_sidebar"
        @add_to_campaign="addInfuencerButton"
        @get_profile_url="getProfileUrl"
      >
      </sidebar-search>
      <sidebar-searches-saved 
        class="open-sidebar-search" 
        :open_sidebar="sidebar_searches_saved" 
        :key="sidebar_searches_saved_update"
        @set_filters="setFiltersRecentSearches"
      ></sidebar-searches-saved>
    </b-row>

    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal_plans_group"
      :month_dad="true"
      :texts="{title: $t('creator.msjTitlePremiumBrand'), subtitle: $t('creator.subtitlePremiumBrand')}"
    />
  </div>
</template>

<script>
import {
  BTable, 
  BAvatar, 
  BAvatarGroup,
  BDropdown,
  BDropdownHeader,
  BBadge, 
  BRow, 
  BCol, 
  BButton, 
  BButtonGroup,
  BMedia,
  BLink, 
  BFormCheckbox,
  BCard, 
  BCardText, 
  BImg,
  BTr,
  BTh,
  BTd,
  BFormGroup,
  BPopover,
} from 'bootstrap-vue'
import { numberFormat, getFormat, checkNumber} from '@/libs/utils/formats'
import { getURL } from '@/libs/utils/urls'
import { getLocalNetworkName, getIconWhite, getIconColor } from '@/libs/utils/icons'
import { getMotivationalPhrase } from '@/libs/utils/others'
import { getUserData, hasPermission } from '@/libs/utils/user'
import { required } from '@validations';
import filtroTiktok from '@/views/buscador/filtroTIktok.vue'
import filtroYoutube from '@/views/buscador/filtroYoutube.vue'
import filtroTwitter from '@/views/buscador/filtroTwitter.vue'
import filtroTwitch from '@/views/buscador/filtroTwitch.vue'
import service from '@/services/search'
import service_getCamp from '@/services/dashboard'
import service_campaigns from '@/services/campaign'
import service_profile from '@/services/others';
import lists_service from '@/services/lists';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'Searcher',
  components: {
    BFormGroup,
    BFormCheckbox,
    BButtonGroup,
    BPopover,
    BAvatarGroup,
    BDropdown,
    BDropdownHeader,
    BImg,
    BTr,
    BTh,
    BTd,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BButton,
    BMedia,
    filtroTiktok,
    filtroYoutube,
    filtroTwitter,
    filtroTwitch,
    BLink,
    BCard,
    BCardText,
    dropdownCampaigns: () => import('@/views/campaings/dropdownCampaigns.vue'),
    dropdownLists: () => import('@/views/campaings/dropdownLists.vue'),
    SidebarSearch: () => import('@/views/buscador/SidebarSearch.vue'),
    SidebarSearchNew: () => import('@/views/buscador/SidebarSearchNew.vue'),
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue'),
    InstagramFilter: () => import('./filters/InstagramFilter.vue'),
    historyFiltersSearch: () => import('./filters/components/historyFiltersSearch.vue'),
    dashboard: () => import('@/views/pages/analyzer/dashboard.vue'),
    sidebarSearchesSaved: () => import('./filters/components/sidebarSearchesSaved.vue'),
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
    boxSearchFree: () => import('@/views/components/banner/boxSearchFree')
  },
  data () {
    return {
      checkNumber,
      numberFormat, 
      getFormat,
      getURL,
      getLocalNetworkName,
      getIconWhite, 
      popover_target: null,
      popover_content: null,
      update_popover: 0,
      networks: [
        {index: 0, network: 'ig'},
        {index: 1, network: 'yt'},
        {index: 2, network: 'tt'},
        {index: 4, network: 'tw'},
        {index: 3, network: 'tr'},
      ],
      networks_avaibles: [
        { value: 'instagram', text: 'Instagram', src: 'ig', index_hype: 1},
        { value: 'youtube', text: 'YouTube',  src: 'yt', index_hype: 2},
        { value: 'tiktok', text: 'TikTok',  src: 'tt', index_hype: 3},
        { value: 'twitter', text: 'Twitter',  src: 'tr', index_hype: 5},
        { value: 'twitch', text: 'Twitch',  src: 'tw', index_hype: 4}
      ],
      select_network: { value: 'instagram', text: 'Instagram', src: 'ig', index_hype: 1},
      selected_sort: null,
      v_select: null,
      sort_options: {
        type: 'fc',
        order: 'desc'
      },
      data_sidebar: null,
      open_sidebar: false,
      update_sidebar: 0,
      checked_influencer: [],
      required,
      netSelected:'instagram',
      addInfluencer: {
        network:'',
        username:'',
        image:'',
        followers:0
      },
      options : [],
      updateTable: 0,
      searchString: '',
      toogleFilter: {
        show: false,
        st: 'ig'
      },
      searchData: {
        hash: localStorage.getItem('accessToken'),
        keywords: '',
        st: 'ig',
        page: 1
      },
      infoTable: [],
      total: 0,
      currentPage: 1,
      local: window.location.origin,
      nextPage: true,
      hasPrev: 100,
      selected_campaign: null,
      selected_all: null,
      selected_all_inner: null,
      change_view_card: true,
      loader_results: false,
      lists: [],
      interval_loading: null,
      initial_filters: {},
      filters_history: {},
      sidebar_searches_saved: false,
      sidebar_searches_saved_update: false,
      loading_results: false,
      log_search: {},
      update_table_results: false,
      loading_service: false,
      update_button_favorite: false,
      go_back_action: false,
      columns_table_selected: [],
      main_locations_filter: [],
      open_modal_plans_group: false,
      update_modal_plans_group: false,
    }
  },
  created () {
    this.handleGetData();
    this.getCampaigns()
    this.getLists();
    this.getMainLocationsForFilter();
    this.columns_table_selected = this.getColumnsSelected()
    this.selected_sort = this.$t('search.selectSortsSearcher')[this.select_network.src].find(i => i.value === 'fc');
  },
  async mounted() {
    this.v_select = (await import('vue-select')).default
  },
  computed: {
    is_free() {
      return getUserData().current_plan.name === 'Free'
    },
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    fields () {
      let table = ''
      switch (this.select_network.src) {
      case 'ig':
        table = 'tableSearch.ig'
        break
      case 'yt':
        table = 'tableSearch.yt'
        break
      case 'tt':
        table = 'tableSearch.tt'
        break
      case 'tr':
        table = 'tableSearch.tr'
        break
      case 'tw':
        table = 'tableSearch.tw'
        break
      }
      return table
    },
    select_sort_options() {
      const sorts_net = this.$t('search.selectSortsSearcher')[this.select_network.src].filter(i => i.is === 'desc')
      if (this.selected_sort.is === 'asc') sorts_net.push(this.selected_sort)
      return sorts_net;
    },
    fields_for_table() {
      return this.$t(`${this.fields}`).filter(i => (i.is_showing === undefined) || i.is_showing === true)
    },
  },
  watch : {
    selected_sort(val) {
      this.sort_options.type = val.value;
      if (val.is) this.sort_options.order = val.is;
      this.getSearchResults(this.searchData)
    },
    '$route.query'(val) {
      if (Object.keys(val).length > 0) {
        this.handleGetData();
      }
    },
    columns_table_selected(val) {
      this.$t(this.fields).forEach((field) => {
        if (val.find(i => i === field.key) && field.is_showing !== undefined) field.is_showing = true
        else if (field.is_showing !== undefined) field.is_showing = false;
      })

      this.$nextTick(() => {
        const categories_index = this.$t(`${this.fields}`).filter(i => (i.is_showing === undefined) || (i.is_showing === true)).findIndex(i => i.key === 'categories');
        const table = this.$refs.table_searcher;
        if (table) {
          table.$el.classList.remove(this.getClassForTable())
          const rows = table.$el.querySelectorAll('tbody tr');
          rows.forEach(row => {
            const cell = row.children[categories_index];
            if (cell) {
              cell.style.minWidth = '550px';
            }
          });
    
        }
      });
    },
    checked_influencer(val) {
      if (this.$refs.table_searcher) {
        const trs_header = document.getElementsByTagName('tr')[1]
        const id_actions_select = document.getElementById('tr-actions-select')

        if (trs_header && val.length > 0) {
          this.selected_all_inner = true;
          id_actions_select.classList.remove('d-none')
          trs_header.style.visibility = 'hidden';
        } else if (trs_header && val.length === 0) {
          this.selected_all_inner = false;
          id_actions_select.classList.add('d-none')
          trs_header.style.visibility = 'visible';
        }
      }
      this.checkCheckboxClasses(val);
    },
    selected_all(val) {
      if (val) {
        this.checkedAll()
        this.selected_all_inner = true;
      } else this.checked_influencer = [];
    },
    selected_all_inner(val) {
      if (!val) {
        this.selected_all = false
        this.checked_influencer = [];
      } else if (!this.change_view_card && val) {
        this.selected_all = true
      }
    },
    change_view_card() {
      setTimeout(() => {
        this.checkCheckboxClasses(this.checked_influencer);
      }, 500)
    },
    'select_network.value'(val) {
      this.columns_table_selected = this.getColumnsSelected()
      this.filters_history = {};
      if (this.$refs.dashboard_outstanding) this.$refs.dashboard_outstanding.changeNetwork(val)
      this.searchData.st = this.select_network.src;
      this.getFilter(true);

      // Better implamentation when change network coming from filters analyzer, cleaning hash url
      // this.initial_filters = {};
      // this.$router.push({name: 'auth-search'}).catch(() => {});
    }
  },
  methods: {
    changeOrder(type) {      
      const current_order = this.sort_options.order;
      const model_for_select = this.$t('search.selectSortsSearcher')[this.select_network.src].find(i => i.value === type && i.is !== current_order)
      this.selected_sort = model_for_select;
    },
    getMainLocationsForFilter() {
      service.getMainLocations().then((response) => {
        this.main_locations_filter = response.countries;
      })
    },
    getColumnsSelected() {
      const selected = []
      this.$t(`${this.fields}`).forEach((i) => {
        if (i.is_showing !== undefined) { 
          selected.push(i.key)
        }
      });
      return selected
    },
    getClassForTable() {
      if (this.select_network.value === 'instagram' || this.select_network.value === 'youtube') return 'main-table-searcher-influencers-ig-yt'
      else if (this.select_network.value === 'tiktok') return 'main-table-searcher-influencers-tt';
      else if (this.select_network.value === 'twitch') return 'main-table-searcher-influencers-tw'
    },
    updatePopover(categories, id_target) {
      this.popover_content = categories.join('<br>');
      this.popover_target = id_target;
      this.update_popover += 1;
    },
    getStyle(i) {
      return `left: ${2 * i}px; z-index: ${ 10 - i}`
    },
    changeLoading(loading) {
      this.loading_service = loading;
    },
    goBackAction() {
      this.go_back_action = true
      this.$refs.filter_network.clearFilters(false);
      this.initial_filters = {};
      this.filters_history = {};

      setTimeout(() => {
        this.$refs.dashboard_outstanding.changeNetwork(this.select_network.value)
        this.searchData.st = this.select_network.src;
      }, 200);
      this.$router.push({name: 'auth-search'}).catch(() => {});
    },
    handleGetData() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const hash = urlParams.get('hash')
      if (hash) {
        this.searchHashQuery(hash)
      } else {
        this.firstPage(this.searchData)
      }
    },
    saveSearch() {
      const uuid_save_log = (Object.keys(this.filters_history).length === 0) || ((Object.keys(this.filters_history).length > 0) && !this.filters_history.is_favorite) ? this.log_search.uuid : this.filters_history.history_search_uuid
      service.toggleFavoriteSearch(uuid_save_log).then((response) => {
        this.filters_history.is_favorite = response.is_favorite;
        this.filters_history.history_search_uuid = (response.is_favorite) ? response.uuid : undefined
        this.update_button_favorite = !this.update_button_favorite;
    
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: this.$t(`filters.${response.is_favorite ? 'addedS' : 'ruleOut'}`),
              icon: 'CheckCircleIcon',
              text: this.$t(`filters.${response.is_favorite ? 'searchAdded' : 'searchQuit'}`),
              variant: 'success'
            }
          })
        })
      })
    },
    setFiltersRecentSearches(filters) {
      const request = filters.request
      request.is_favorite = filters.is_favorite;
      request.history_search_uuid = filters.uuid;
      this.select_network = this.networks_avaibles.find(i => i.src === request.st)
      setTimeout(() => {
        this.filters_history = request;
      }, 20);
    },
    updateSelectNetwork(select_network) {
      this.select_network = select_network;
    },
    searchHashQuery(hash) {
      const jsonString = atob(hash);
      const search = JSON.parse(jsonString);
      const network = this.networks[search.st - 1]
      this.select_network = this.networks_avaibles.find(i => i.src === network.network)
      this.searchData.st = network.network
      this.toogleFilter.st = network.network
      this.initial_filters = search
    },
    getFormatInfluencerToAdd() {
      const influencers_to_add = [];
      this.checked_influencer.map((item) => {
        const influencer = this.infoTable[item - 1];
        influencers_to_add.push({
          influencer: influencer.username.name.username,
          username: influencer.username.name.username, 
          network: this.select_network.value, 
          image: influencer.username.avatar,
          followers: influencer.followers, 
          channel_id: (this.select_network.value === 'youtube') ? influencer.username.name.social_id : undefined,
          categories: influencer.categories.all_categories,
        });
      });
      return influencers_to_add;
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.infoTable.length; i++) {
          if (this.change_view_card && this.$refs[`checkbox_table_${i}`]) {
            this.$refs[`checkbox_table_${i}`].$el.classList.remove('d-none')
            this.$refs[`checkbox_table_${i}`].$el.classList.remove('checkbox-table-searcher')
            this.$refs[`id_table_${i}`].classList.add('d-none')
          } else if (this.$refs[`checkbox_card_${i}`]) {
            this.$refs[`checkbox_card_${i}`][0].$el.classList.remove('d-none')
          }
        }
      } else {
        for (let i = 1; i <= this.infoTable.length; i++) {
          if (this.change_view_card && this.$refs[`checkbox_table_${i}`]) {
            this.$refs[`checkbox_table_${i}`].$el.classList.add('d-none')
            this.$refs[`checkbox_table_${i}`].$el.classList.add('checkbox-table-searcher')
            this.$refs[`id_table_${i}`].classList.remove('d-none') 
          } else if (this.$refs[`checkbox_card_${i}`]) {
            this.$refs[`checkbox_card_${i}`][0].$el.classList.add('d-none')
          }
        }
      }
    },
    closedDropdown() {
      for (let i = 0; i < this.lists.length; i++) {
        this.$refs[`plus-icon-list-${i}`][0].classList.remove('d-none');
        this.$refs[`check-icon-list-${i}`][0].classList.add('d-none');
      }
      for (let i = 0; i < this.options.length; i++) {
        this.$refs[`plus-icon-campaign-${i}`][0].classList.remove('d-none');
        this.$refs[`check-icon-campaign-${i}`][0].classList.add('d-none');
      }
    },
    toastSelectInfluencer() {
      loadToastificationContent().then((module) => {
        this.$toast({
          component: module.default,
          position: "top-right",
          props: {
            title:  this.$t('search.check_influencer_title'),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t('search.check_influencer_text')
          }
        });
      });
    },
    addToList(list_uuid, index_spinner) {
      this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.remove('d-none');
      this.selected_all = null;
      const influencers_ar = [];
      this.checked_influencer.map((id) => {
        const influencer = this.formatDataInfluencer(this.infoTable.find(data => data.id === id));
        influencers_ar.push(influencer);
      });
      lists_service.bulkAddInfluencer(list_uuid, {influencers: influencers_ar}).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            position: "top-right",
            props: {
              title:  this.$t('lists.addinfluencer'),
              icon: "CoffeeIcon",
              variant: "success",
              text: this.$t('lists.addlist')
            }
          });
        });
        setTimeout(() => {
          this.$refs[`spinner-dropdown-${index_spinner}`][0].classList.add('d-none');
          this.$refs[`plus-icon-list-${index_spinner}`][0].classList.add('d-none');
          this.$refs[`check-icon-list-${index_spinner}`][0].classList.remove('d-none');
        }, 500);
      });
    },
    getLists() {
      lists_service.getLists().then((response) => {
        const lists = this.orderLists(response.results)
        lists.map((item) => {
          this.lists.push({value: item.uuid, text: item.title})
        });
      });
    },
    orderLists(lists) {
      lists.sort(function(a, b) {
        return a.order - b.order;
      });

      return lists
    },
    rowTableClick(data) {
      this.open_sidebar = true; 
      this.update_sidebar += 1;
      this.data_sidebar = data;
    },
    rowHovered(data) {
      const nets = document.getElementsByClassName(`icon-networks-${data.id}`)
      nets.forEach((i, index) => {
        i.classList.remove('gray-icons-colors')
        const class_to_add = getIconColor(this.networks_avaibles.find(n => n.index_hype === index + 1).value, false).split(' ')[1]
        i.classList.add('padding-03', class_to_add)
      })
    },
    rowUnhovered(data) {
      const nets = document.getElementsByClassName(`icon-networks-${data.id}`)
      nets.forEach((i) => {
        i.classList.add('gray-icons-colors')
      })
    },
    checkedAll() {
      this.checked_influencer = [];
      this.infoTable.forEach((item) => {
        this.checked_influencer.push(item.id)
      })
    },
    handleCheck(campaign_uuid = null, index_spinner = null) {
      this.selected_campaign = campaign_uuid;
      this.selected_all = null;
      if (this.checked_influencer.length === 0) {
        this.show_modal = false;
        this.change_modal = false;
        this.addInfluencerToCampaign()
      } else {
        this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
        this.addCheckedInfluencer(index_spinner);
      }
    },
    formatDataInfluencer(data) {
      const object_influencer = {}
      object_influencer.influencer = this.toogleFilter.st === 'yt' ? data.username.name.social_id : data.username.name.username;
      object_influencer.username = this.toogleFilter.st === 'yt' ? data.username.name.social_id : data.username.name.username;
      object_influencer.network = this.netSelected;
      object_influencer.image = data.username.avatar;
      object_influencer.followers = data.audience;
      object_influencer.categories = data.category;

      return object_influencer
    },
    addCheckedInfluencer(index_spinner) {
      const influencers = []
      this.checked_influencer.map((id) => {
        const influencer = this.formatDataInfluencer(this.infoTable.find(data => data.id === id))
        influencers.push(influencer)
      })
      service_campaigns.addInfluencersCampaign(this.selected_campaign, influencers)
        .then(() => {
          this.selected_campaign = null;
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('search.added'),
                icon: 'CheckCircleIcon',
                text: this.$t('search.hasBeenAdded'),
                variant: 'success'
              }
            })
          })
          setTimeout(() => {
            this.$refs[`plus-icon-campaign-${index_spinner}`][0].classList.add('d-none')
            this.$refs[`check-icon-campaign-${index_spinner}`][0].classList.remove('d-none')
            this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
          }, 500);
          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            });
        })
    },
    getProfileUrl(actions) {
      if (hasPermission('view_profile')) {
        const network = getLocalNetworkName(this.select_network.src)
        const username = this.select_network.src === 'yt' ? actions.social_id : actions.username
        window.open(`${this.local}/perfil/${network}/${username}`, '_blank')
      } else {
        this.open_modal_plans_group = true;
        this.update_modal_plans_group = !this.update_modal_plans_group;
      }
    },
    addInfuencerButton(data, uuid = null) {
      this.addInfluencer.username = this.toogleFilter.st === 'yt' ? data.username.name.social_id : data.username.name.username;
      this.addInfluencer.network = this.netSelected;
      this.addInfluencer.image = data.username.avatar;
      this.addInfluencer.followers = data.followers;
      if (uuid === null) {
        this.change_modal = !this.change_modal;
        this.show_modal = true;
      } else {
        this.selected_campaign = uuid;
        this.addInfluencerToCampaign();
      }
    },
    addInfluencerToCampaign() {
      const form = new FormData()
      form.set('username', this.addInfluencer.username);
      form.set('network', this.addInfluencer.network);
      form.set('image', this.addInfluencer.image);
      form.set('followers', this.addInfluencer.followers);
      service_campaigns.addInfluncerv3(this.selected_campaign, form).then((e) => {
        if (e.status === 400) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('campaigns.errorInfluencer'),
                icon: 'BellIcon',
                text: e.data.response.message,
                variant: 'danger'
              }
            },
            {
              position: 'top-left'
            })
          })
        } else if (e.status === 500) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('campaigns.errorInfluencer'),
                icon: 'BellIcon',
                text: e.data.response.message,
                variant: 'danger'
              }
            },
            {
              position: 'top-left'
            })
          })
        } else {
          this.selected_campaign = null;
          this.$bvModal.hide('selectCampaign')
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('search.added'),
                icon: 'CheckCircleIcon',
                text: this.$t('search.hasBeenAdded'),
                variant: 'success'
              }
            })
          })

          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
        }
      });
    },

    getCampaigns() {
      service_getCamp.getCampaign().then((res) => {
        res.response.results.filter((item) => {
          const obj = {value:item.uuid, text: item.name}
          this.options.push(obj)
        });
      });
    },

    getFilter(searching) {
      this.searchString = ''
      this.searchData.keywords = ''
      this.currentPage = 1
      this.searchData.page = this.currentPage

      this.toogleFilter.st = this.searchData.st
      this.checked_influencer = []

      if (searching) this.firstPage(this.searchData)
      else this.searchData = {
        keywords: '',
        st: this.toogleFilter.st,
        page: 1
      }
    },
    //pagination methods
    next () {
      if (this.nextPage) {
        this.currentPage += 1
        this.searchData.page = this.currentPage
        this.getSearchResults(this.searchData)
      }

    },
    prev () {
      if (this.hasPrev > 100) {
        this.currentPage -= 1
        this.searchData.page = this.currentPage
        this.getSearchResults(this.searchData)
      }
    },
    last () {
      if (this.nextPage) {
        this.currentPage = Math.round(this.total / 100)
        this.searchData.page = this.currentPage
        this.getSearchResults(this.searchData)
      }

    },
    first () {
      if (this.hasPrev > 100) {
        this.currentPage = 1
        this.searchData.page = 1
        this.firstPage(this.searchData)
      }

    },
    firstPage(datos) {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      return new Promise((resolve,reject) => {
        service.getFirstPage(datos)
          .then(response => {
            this.infoTable = []
            this.total = response.data.total
            if (response.data.data) this.parseData(response.data)
            this.updateTable += 1
            this.nextPage = response.data.has_next
            this.hasPrev = response.data.next_offset
            clearInterval(this.interval_loading)
            this.$vs.loading.close()
            resolve(response)
          }).catch(e => {
            reject(e)
          })
      })
    },
    // finish pagination methods
    //onSearch
    getSearchResults (datos) {
      datos.hash = localStorage.getItem('accessToken');
      this.loader_results = true;

      datos.sort_type = this.sort_options.type
      datos.sort_order = this.sort_options.order
      datos.counter = this.go_back_action ? 1 : 0;
      service.saveLogSearch(datos).then((response) => {
        this.log_search = response.data.response;
        this.go_back_action = false;
      })

      return new Promise((resolve,reject) => {
        service.getResults(datos)
          .then(response => {
            if (response.data.error) {
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('campaigns.error_status'),
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: this.$t('search.workingIssue')
                  }
                })
              })
              this.loading_results = false;
              this.infoTable = []
              this.total = 0
              return;
            }
            this.infoTable = []
            this.total = response.data.total
            if (response.data.data) this.parseData(response.data)
            this.updateTable += 1
            this.nextPage = response.data.has_next
            this.hasPrev = response.data.next_offset
            this.loader_results = false;
            resolve(response)
          }).catch(e => {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: ''
                }
              })
            })
            reject(e);
            this.loader_results = false;
          })
      })
    },
    searchFilters(datos, loading_results) {
      this.currentPage = 1
      this.searchData = datos
      this.getSearchResults(this.searchData)
      this.loading_results = loading_results;
    },
    exportSelectedInfluencers() {
      const headers = this.$t(`${this.fields}`).map(i => i.label);
      headers.splice(3, 0, 'Enlace')
      const rows = [headers];
      this.infoTable.forEach((data, index) => {
        const info_row = [index + 1, data.username.name.username, data.followers, getURL(getLocalNetworkName(this.select_network.value), data.username.name.username, this.select_network.value === 'youtube')]
        switch (this.select_network.value) {
        case 'instagram':
          info_row.push(
            data.audience,
            data.tp,
            data.mentions.total,
            data.email ? 1 : 0,
            data.networks.join(' - '),
            data.categories.all_categories.join(' - ')
          )
          
          break;
        case 'youtube':
          info_row.push(
            data.average_views,
            data.views,
            data.tp,
            data.email ? 1 : 0,
            data.networks.join(' - '),
            data.categories.all_categories.join(' - ')
          )
          break;
        case 'tiktok':
          info_row.push(
            data.likes,
            data.average_views,
            data.tp,
            data.mentions.total,
            data.email ? 1 : 0,
            data.networks.join(' - '),
            data.categories.all_categories.join(' - ')
          )
          break;
        case 'twitter':
          info_row.push(
            data.tp,
            data.networks.join(' - '),
            data.tweets,
            data.retweets
          )
          break;
        case 'twitch':
          info_row.push(
            data.average_views,
            data.networks.join(' - '),
            data.categories.all_categories.join(' - ')
          )
          break;
        default:
          break;
        }
        rows.push(info_row);
      });
      const csv = rows.map(e => e.join(",")).join("\n")
      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const url = URL.createObjectURL(blob);

      downloadLink.href = url;
      downloadLink.download = "Influencers_lista.csv";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    getName(cat) {
      let entry = false
      switch (this.searchData.st) {
      case 'ig':
        entry = this.$t('instaCategories').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;
      case 'yt':
        entry = this.$t('youtubeCategory').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;

      case 'tt':
        entry = this.$t('tiktokCategory').find(function(e) { return e.value === cat });
        if (entry) return entry.text
        break;
      }

      return ''
    },
    getCategoriesLabel(array_categories) {
      
      if (isNaN(array_categories[0])) {
        return {all_categories: array_categories, first_category: array_categories[0]};
      } else {
        const categories = this.$refs.filter_network ? this.$refs.filter_network.total_categories_service : null
  
        if (categories) {
          const new_m = array_categories.map((index_category) => {
            if (this.select_network.src === 'ig') {
              if (categories['labels'].find(i => i.id === index_category)) return categories['labels'].find(i => i.id === index_category).title
            } else {
              for (const key in categories) {
                if (categories[key].find(i => i.id === index_category))
                  return categories[key].find(i => i.id === index_category).title
              }
            }
          })
          return {all_categories: new_m, first_category: new_m[0]};
        }
        return {all_categories: [], first_category: ''};
      }
    },
    //utils
    parseData(response) {
      const indexes = {
        account: 'un',
        followers: 'fc',
        audience: 'rfc',
        er: 'er',
        aqs: 'aqs',
        category: 'category',
        mentions: 'mentions',
        email: 'has_email',
        networks: 'networks',
        average_views: 'pv_a',
        average_views_tt: 'views_avg',
        views_30: 'views_30d',
        likes: 'likes',
        twts: 'twts',
        rtwts: 'rtwts',
        average_views_twitch: 'live_viewers_avg',
        games: 'games'
      };
      const index = {};
      for (const key in indexes) {
        index[key] = response.columns.findIndex(i => i.name === indexes[key]);
      }


      response.data.forEach(item => {
        
        const row = {}
        row.id = item[0]
        row.username = {
          avatar: item[index.account].ava,
          name: item[index.account],
        }
        row.followers = item[index.followers]
        row.networks = item[index.networks].map(i => {
          return i < 6 ? this.networks_avaibles.find(j => j.index_hype === i).value : 'instagram'
        })

        switch (this.searchData.st) {
        case 'ig':
          row.audience = item[index.audience]
          row.tp = item[index.er].toFixed(2)
          row.quality = item[index.aqs]
          row.mentions = item[index.mentions]
          row.categories = this.getCategoriesLabel(item[index.category])
          row.email = item[index.email]
          break;
        case 'yt':
          row.views = item[index.views_30]
          row.average_views = item[index.average_views];
          row.actions = {
            username: item[index.account].username ? item[index.account].username : item[index.account].fullname,
            channel_id: item[index.account].social_id,
            fullname: item[index.account].username ? item[index.account].username : item[index.account].fullname,
          }
          row.tp = item[index.er].toFixed(2)
          row.categories = this.getCategoriesLabel(item[index.category])
          row.email = item[index.email]
          break;
        case 'tt':
          row.mentions = item[index.mentions]
          row.average_views = item[index.average_views_tt];
          row.likes = item[index.likes];
          row.tp = item[index.er].toFixed(2)
          row.categories = this.getCategoriesLabel(item[index.category])
          row.email = item[index.email]
          break;
        case 'tw':
          row.average_views = item[index.average_views_twitch];
          row.categories = this.getCategoriesLabel(item[index.games])
          row.email = item[index.email]
          
          break;
        case 'tr':
          row.tp = item[index.er].toFixed(2)
          row.tweets = item[index.twts]
          row.retweets = item[index.rtwts]
         
          break;
        default:
          return 0
        }
        this.infoTable.push(row)
      })
    },
    colorStatus (item) {
      if (!item.quality) return '#F17171';
      if (item.quality.title === 'Muy bueno') return 'yellowgreen'
      if (item.quality.title === 'Promedio') return '#D5B900'
      if (item.quality.title === 'Excelente') return '#00AE65'
      if (item.quality.title === 'Buen') return 'darkorange'
      if (item.quality.title === 'Bajo') return '#EC4242'
      if (item.quality.title === 'Podria ser mejorado') return '#F17171'
      return 'yellowgreen'
    }
  }
}
</script>

<style lang="scss">
.select-sort-options-searcher {
  .vs__dropdown-toggle {
    font-family: 'avenir-medium';
    width: 300px;
    height: 42px;
    border: none;
    box-shadow: none !important;
  }
  .vs__selected  {  
    position: static;
    opacity: 1 !important;
    white-space: nowrap;
  }
  .vs__search {
    opacity: 0;
  }
  .sort-icon-svg {
    width: 15px;
    height: 15px;
  }
  .vs__open-indicator {
    display: none;
  }
  .vs__dropdown-menu {
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.15);
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}
.b-dropdown-header-location-filter-padding .dropdown-header {
  padding: 0 !important;
}
.container-add-list-campaigns-table-template {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.px-25 {
  padding: 0 2.2em !important;
  .h-56 {
    height: 56px;
  }
}
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.width-550 {
  width: 550px;
}
.width-100 {
  width: 100px;
}

.width-30 {
  width: 30px;
}

.main-table-searcher-influencers-ig-yt {
  tbody {
    td:nth-child(9) {
      width: 550px;
    }
    td:nth-child(6) {
      width: 100px;
    }
    td:nth-child(7) {
      width: 30px;
    }
    td:nth-child(9) {
      min-width: 550px !important;
    }
  }
}
#table-searcher {
  tbody {
    td:nth-child(1) {
      width: 60px;
    }
    td:nth-child(2) {
      width: 350px;
    }
  }
}
.main-table-searcher-influencers-tt {
  tbody {
    td:nth-child(10) {
      width: 550px;
    }
  }
}
.main-table-searcher-influencers-tw {
  tbody {
    td:nth-child(6) {
      width: 550px;
    }
  }
}
.popover-categories {
  background: rgba(0, 0, 0, 0.758) !important;
  .arrow::after {
    border-left-color: rgba(0, 0, 0, 0.758) !important;
  }
}
.dropdown-hidding-columns-searcher {
  .btn {
    padding: 0;
  }
}
</style>

<style scoped lang="scss">
.dashboard-analyzer-container {
  min-height: 500px;
}
.overflow-hidden-main-container-results-table {
  overflow-y: hidden !important;
  overflow-x: auto;
  position: relative;
}
.table-wrapper {
  position: relative;
  overflow-x: auto;
}
.w-max-content {
  width: 100%;
}
.top-19 {
  top: 19em;
}
@media (min-width: 1630px) {
  .table-wrapper {
    width: 100%;
    overflow-x: visible;
  }
}
.container-free-results-absolute {
  backdrop-filter: blur(7px);
  width: 100%;
  height: 100%;
  background: #f8f8f88a;
  position: absolute;
  z-index: 10;
}
.second-potition {
  top: 38.9em;
}
.font-bold-black {
  font-weight: bolder;
  color: black;
}
.p-05 {
  padding: 0.5em;
}
.full-name-influencer-hover:hover {
  text-decoration: underline;
}
.gray-icons-colors {
  background-color: #4F5969;
  padding: 0.3em;
}
.w-30 {
  width: 55px !important; 
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.main-container-cell-id {
  width: 20px !important;
  height: 20.3px;
}
.size-16-icons {
  width: 16px;
  height: 16px;
}
.h-16 {
  height: 27px;
  width: 100%;
}
.size-avatar-category {
  width: auto;
}
.avatar-categories {
  background-color: #fcfcfc;
  border: 1px solid #E7E8E8;
  font-family: 'avenir-medium';
  padding: 0.2em 0.9em;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow-x: hidden;
}
.p-10 {
  padding: 8px 10px;
}
.ml-05 {
  margin-left: 0.3em !important;
}
.body-button-center{
  position: relative;
  background-color: #e9ecef;
  padding: .25rem .25rem;
  border-radius: .375rem;
}
.button-outline-header-grid-active {
  background: white;
  padding: 5px 10px;
  border-radius: 6px !important;
}

.button-outline-header-grid-no-active {
  background: transparent;
  padding: 5px 10px;
  border: none;
}

.actions-top-header-table {
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  flex-wrap: wrap;
  .button-outline-header {
    background: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    padding: 10px;
    margin-left: 10px;
    .img-icon {
      height: 18px;
      width: 18px;
    }
    &:active {
      background: rgb(243, 243, 243);
    }
  }
}
.arrow-left-go-back-clean {
  font-size: 25px;
  margin-bottom: 0.4em;
  cursor: pointer;
}
</style>
<style lang="scss">
.network-x-search {
  width: 17px;
  margin-right: 4px;
  margin-bottom: 3px
}
.responsive-mode-views {
  justify-content: space-between;
}
.container-mode-views {
  display: flex;
  align-items: center;
}
.select-items {
  display: flex;
  justify-content: center;
  border-radius: 100px;
  width: 30px;
}
.row-main-searcher {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 1080px;
}
.size-icon{
  font-size: 1em;
  padding: 2px;
}
.ancho{
    width: 200px;
}
.tabs-searcher-networks .nav-tabs {
  margin: 0 !important;
}
.tabs-searcher-networks .nav-link {
  color: #718096;
  padding: 0.7em;
  font-weight: bolder;
  letter-spacing: -0.3px;
  font-size: 13px;
}
.tabs-searcher-networks .nav-link.active {
  background-color: white;
  border-top-left-radius: 0.7em;
  border-top-right-radius: 0.7em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: black;
}
.color-searcher-tab-instagram > svg {
  color: #ed4960 !important;
}
.color-searcher-tab-youtube > svg {
  color: #ff051d !important;
}
.color-searcher-tab-tiktok > span {
  color: #ff0653 !important;
}
.color-searcher-tab-twitch > svg {
  color: #9449fa !important;
}
.color-searcher-tab-twitter > svg {
  color: #009cec !important;
}
.tiktok-searcher-icon{
  width: 20px;
  height: 20px;
  font-size: 16px;
  color: #718096;
}
.nav-tabs .nav-link:after {
  background: none !important;
}
.force-color {
  color: #0f131a;
}
.border-icon{
  font-size: 0.8em;
  border-radius: 50%; 
  background: white; 
  padding: 2px;
  position: absolute; 
  bottom: -5px; 
  right: -3px;
}
.filter-outline {
  border: 1px solid transparent;
  position: relative;  
  &:nth-child(2), &:nth-child(4) {
    border-left: 1px solid #E2E8F0;
  }

  @media(max-width: 767px) {
    border: 1px solid #E2E8F0;
    border-radius: 0.7em;
    margin-bottom: 1em;
  }
  @media(min-width: 991px) {
    border-left: 1px solid #E2E8F0;
  }
}
.color-gray-light {
  color: #718096;
  font-family: 'avenir-light';
}
.outline-gray-light .btn {
  color: #718096 !important;
  border: 1px solid #ccc;
}
.filter-outline:hover {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 250ms;
}
.filter-outline:hover + .filter-outline {
  @media(min-width: 991px) {
    border-left: 1px solid transparent;
  }
}
.border-right-light {
  border-right: 0.05em solid #E2E8F0;
}
.font-weight-results {
  text-align: left;
  font-weight: bolder;
  padding: 0.4em 0;
}
.h-100 {
  height: 100% !important;
}
.dropdown-filters {
  height: 100% !important;

  .dropdown-menu {
    width: 250px !important;
  }
}
.dropdown-filters .btn {
  height: 100% !important;
  padding: 12px 16px !important;
}
.button-gender {
  border: 1px solid #ccc;
  padding: 1em;
  overflow: hidden;
}
.button-gender:hover {
  background-color: rgb(231, 231, 231);
  cursor: pointer;
}
.border-radius-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.border-radius-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.white-space-wrap {
  white-space: pre-wrap;
}
.background-clicked-form {
  background-color: rgb(231, 231, 231);
}
.white-space-wrap {
  white-space: pre-wrap;
}
.chev-more-filters {
  color: #a0aec0;
  margin-left: 0.5em;
}
.toggle-collapse-filters:hover .chev-more-filters {
  color: #4b4b4b;
  transition: all 500ms;
}
.more-filters-button, .clear-filters-button {
  background-color: white;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin: 0.7em 0 1.7em;
  border-radius: 0.42rem;
  height: 41.5px;
}
.disabled-button-categories {
  color: rgb(218, 218, 218);
  font-weight: 800;
}
.more-filters-button > button {
  width: 100%;
  color: #009cec;
}
.more-filters-button > button:hover {
  color: #006394;
}
.clear-filters-button > button {
  width: 100%;
  color: #82868b;
}
.background-white {
  background-color: white !important;
  overflow-x: hidden !important;
}
.footer-sidebar-filters {
  overflow-y: hidden;
  padding-top: 2em;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 15px;
}
.filters_used_number {
  display: block;
  width: 1.4em;
  height: 1.4em;
  padding: 0.25em;
  background-color: #009cec;
  color: white;
  border-radius: 100%;
  font-size: 14px;
}
.text-filter-under {
  color: #718096;
  font-size: 12px;
}
.thead-light th {
  background-color: white !important;
}
#table-searcher {
  padding: 1em;
  width: 100%;
}
#table-searcher-card .card-body {
  padding: 0;
}
.table-responsive {
  overflow: visible;
}
#table-searcher td {
  border-bottom: 1px solid #eff3f6;
}
#table-searcher tr {
  background-color: white;
}
#table-searcher tr:hover {
  background-color: #F5F8FA;
  cursor: pointer;
  transition: all 100ms;
}
.checkbox-table-searcher {
  display: none !important;
}
.position-absolute-checkbox-searcher {
  /* position: absolute; */
  /* top: -7px; */
}
.light-secondary-v2 {
  background-color: #EFF3F6;
  color: #718096;
  font-weight: 400;
}
#table-searcher tr:hover .checkbox-table-searcher {
  display: block !important;
}
#table-searcher tr:hover .id-table-searcher {
  display: none;
}
.table-searcher-padding th {
  padding: 16px 2em;
}
#table-searcher td {
  /* padding: 0; */
}
.font-size-9 {
  font-size: 8px;
}
.text-muted-username {
  color: #8490A4;
  font-size: 14px;
  font-family: 'avenir-medium';
}
.media-container-avatar {
  height: 50px;
}
.card-view-searcher  .card-image-influencer {
  width: 150px !important;
  height: 160px !important;
  object-fit: cover !important;
  align-items: center !important;
}
.follow-er-view-card {
  font-weight: 500;
}
.font-size-13 {
  font-size: 13px;
}
.username-card-view:hover > h4 {
  transition: all 250ms;
  color: #009cec;
}
.checkbox-card-view {
  position: absolute;
  right: 0;
  top: 8px;
}
.card-view-searcher > .card-body {
  padding: 0;
  margin: 0 !important;
  display: flex;
  max-height: 160px;
  height: auto;
}
.card-view-searcher:hover .checkbox-card-view {
  display: block !important;
}
.card-view-searcher:hover {
  transform: scale(1.05, 1.05);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.b-sidebar-backdrop {
  background-color: rgba(34, 41, 47);
  animation: sidebar-black-bakground 300ms;
}
.open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  top: 0px;
  left: 0px;
}
.toggle-collapse-filters > span, .toggle-collapse-filters > strong > svg {
  margin-right: 0.4em;
}
.color-gray-button {
  color: #82868b;
  font-weight: 500;
  line-height: 5px;
}
.outline-secondary-hover {
  border-color: #82868b;
  color: #82868b;
}
.outline-secondary-hover:hover {
  color: #82868b;
  background-color: white;
}
.category-filters-searcher {
  width: 1300px !important;
  font-size: 13px;
  max-height: 500px;
  overflow: hidden;
}

.input-search-by-influencer {
  border: none ;
  box-shadow: none;
}
.input-search-by-influencer:focus {
  box-shadow: none !important;
}
.span-add-keyword {
  color: #009cec;
  cursor: pointer;
  font-weight: bold;
}
.chevron-dropdown-categories {
  position: absolute;
  right: 0px;
}
@media(max-width: 525px) { 
  .container-mode-views {
    align-items: center;
    display: block;
    margin-top: 12px;
  }
  .responsive-button {
    margin-block: 20px;
  }
  .responsive-mode-views {
    justify-content: center;
  }
}
@media(min-width: 1200px) { 
  .more-filters-button {
    order: unset !important;
  }
}
@keyframes sidebar-black-bakground {
  0% {
    opacity: 0;
  }
  100% {
    background-color: rgba(34, 41, 47, 0.75);  
  }
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
@media(max-width: 575px){
    .tabs-center ul li{
      width: 100% !important;
    }
  }
@media(min-width: 576px) and (max-width: 768px){
  .tabs-center ul li{
    width: 50% !important;
  }
}
</style>