<template>
  <div>
    <b-card id="card-twitter-filters" class="mb-0">
      <select-network-search 
        :networks_avaibles="networks_avaibles"
        :select_network_dad="select_network"
        @update_select_network="updateSelectNetwork"
        class="select-network-search"
      ></select-network-search>
      <b-row class="m-0 w-100">
        <!-- audience location -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100" ref="filter_influencer_location">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left align-middle color-gray-light d-block mb-1">
                    <span class="search-Map-Pin4"></span> {{$t("search.influencerLocation")}} 
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_influencer_location}} </p>
              </div>
            </template>
            <b-dropdown-header :key="update_location" class="b-dropdown-header-location-filter-padding">
              <div v-for="(location, index) in influencer_locations" :key="index">
                <audience-location-filter
                  :key="change_influencer_location"
                  :show_remove="index > 0"
                  :index="index"
                  :location="location"
                  :show_slider="false"
                  :main_locations_filter="main_locations_filter"
                  @changeLocationAudienceChild="changeLocationInfluencer"
                  @removeAudienceLocationChild="removeInfluencerLocation"
                />
              </div>
              <div v-if="Object.keys(this.influencer_locations[0]).length > 0" class="p-custom">
                <hr/>
                <b-link v-show="this.influencer_locations.length < 3" @click="addInfluencerLocation()" class="text-blue">{{$t('search.addLocation')}}</b-link>
                <div v-show="this.influencer_locations.length === 3" class="white-space-wrap">{{$t('search.max3Locations')}}</div>
              </div>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- audience size -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content> 
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light mb-1 d-block">
                    <span class="search-Users"></span>{{$t("search.audience_size")}}
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{text_followers}} </p>
              </div>
            </template>
            <b-dropdown-header>
              <followers-filter @changeFollowers="changeFollowers" :key="update_followers_filter"/>
            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Tweets in 30 days -->
        <b-col class="col-md-6 col-lg filter-outline p-0" cols="12">
          <b-dropdown block no-caret class="dropdown-filters" variant="outline-gray-light" menu-class="w-100">
            <template #button-content>
              <div class="d-flex flex-column justify-content-between h-100">
                <b-form-group class="mb-0">
                  <span class="text-left color-gray-light mb-1 d-block">
                    <b-icon class="search-icon icon-size-14" icon="share"/><span class="ml-1">{{$t("search.average_tweets")}}</span>
                  </span>
                </b-form-group>
                <p class="text-left font-weight-bold m-0">{{average_tweets ? separatebycomma(average_tweets) : $t('search.realty.any')}} </p>
              </div>
            </template>
            <b-dropdown-header>
              <view-avg-slider 
                :only_from="true" 
                :slider="average_tweets" 
                :interval="20" 
                :max_value="2000" 
                :min_value="0" 
                :options_numbers="[10, 100, 1000, 2000]"
                identifier="average_tweets"
                @changeSlide="changeSlideComponent"
              />

            </b-dropdown-header>
          </b-dropdown>
        </b-col>

        <!-- Boton more filters -->
        <div class="d-flex align-items-center container-more-filters">
          <b-button variant="outline-secondary" class="d-flex align-items-center w-100" @click="open_sidebar = true"><b-icon icon="filter" class="mr-03"/>{{$t('search.more_filters')}}</b-button>
        </div>
      </b-row>
    </b-card>
    <search-by-actions
      :data="{
        total_results,
        loading,
        searched,
        no_categories: true
      }"
      @sendFilters="sendFilters()"
      @add_keyword_filter="addKeywordFilter"
      ref="search_by_actions"
    ></search-by-actions>
    <apply-filters :filters="checkFiltersUsed()" @deleteFilter="deleteFilter" @updateModel="updateModel"/>
    <b-sidebar 
      v-model="open_sidebar"
      backdrop
      shadow
      right
      width="700px"
      body-class="d-flex flex-column background-white"
      no-header
      footer-class="background-white footer-sidebar-filters"
      class="sidebar-filters-nets"
    >
    <feather-icon
        class="close-component"
        icon="XIcon"
        size="16"
        @click="open_sidebar = false"
    />
      <b-row class="p-2">
        <b-col class="col-12 col-md">
          <div>
            <h4 class="blueC"><strong>{{$t("search.influencer")}}</strong></h4>
          </div>

          <!-- Idioma -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('influencer_language')">
                <span class="search-Globe-1"></span><strong> {{$t("search.influencerLanguage")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_influencer_language"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_influencer_language"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_influencer_language">
              <b-form-select
                v-model="influencer_language"
                :options="$t('instaIdioma')"
              />
            </div>
          </div>

          <!-- Solo verificados -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('is_verified')">
                <span class="search-Check-1"></span><strong> {{$t("search.onlyveri")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_is_verified"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_is_verified"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_is_verified">
              <options-filters :options="$t('instaVerificado')" @optionsFilter="filterVerified" :key="update_is_verified"></options-filters>
            </div>
          </div>

          <!-- Información de contacto -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('info_contact')">
                <span class="search-Information-booklet"></span><strong> {{$t("search.contact")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_info_contact"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_info_contact"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_info_contact">
              <options-filters :options="$t('instaContacto')" @optionsFilter="filterHasContacts" :key="update_info_contact"></options-filters>
            </div>
          </div>
        </b-col>

        <b-col class="col-12 col-md">
          <!-- Filtros rendimiento -->
          <div>
            <h4 class="blueC"><strong>{{$t("search.performance")}}</strong></h4>
          </div>

          <!-- Engagement rate -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('engagement_rate')">
                <span class="search-Heart-Beat"></span><strong> {{$t("search.engagementRate")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_engagement_rate"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_engagement_rate"/>
              </span>              
              <div class="my-1" v-if="engagement_rate > 0">{{engagement_rate}} {{$t("search.engagementRate")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_engagement_rate">
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-model="engagement_rate"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="40"
                :min="0"
                :interval="0.5"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}'"
              />
            </div>
          </div>

          <!-- Aumento de seguidores -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('follower_days')">
                  <span class="search-Increasing-Chart1"></span><strong> {{$t("search.increaseFollowers")}}</strong>
                  <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_follower_days"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_follower_days"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_follower_days">
              <b-form-select
                v-model="followers_days"
                :options="$t('days')"
              />
              <div class="my-1" v-if="followers_days > 0">{{$t("search.increase")}} {{percentage_followers_growth}}%</div>
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-if="followers_days"
                v-model="percentage_followers_growth"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="99"
                :min="1"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}%'"
              />
            </div>
          </div>

          <!-- Publicación más reciente -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('most_recent')">
                <span class="search-Calendar"></span><strong> {{$t("search.mostRecent")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_most_recent"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_most_recent"/>
              </span>              
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_most_recent">
              <span class="my-1" v-if="most_recent > 0"> {{most_recent}} {{$t("search.days")}}</span>
              <component
                :is="vue_slider"
                :tooltip="'always'"
                class="mt-3"
                v-model="most_recent"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="100"
                :min="0"
                :interval="1"
                @drag-end="changeSlide"
                :tooltip-formatter="'{value}'"
              />
            </div>
          </div>

          <!-- Respuestas en 30 días -->
          <div class="py-2 border-bottom">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_replies')">
                <strong><b-icon class="search-icon" icon="chat-left-text"/>{{$t("search.averageReplies")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_replies"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_replies"/>
              </span>              
              <span class="my-2" v-if="average_replies > 0"> {{average_replies}} {{$t("search.replies")}}</span>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_replies">
              <component
                :is="vue_slider"
                class="mt-3"
                v-model="average_replies"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="2000"
                :min="0"
                :interval="20"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              />
            </div>
          </div>

          <!-- Likes en 30 días -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_likes')">
                <strong><b-icon class="search-icon" icon="hand-thumbs-up"/>{{$t("search.averageLikes")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_likes"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_likes"/>
              </span>              
              <div class="my-1" v-if="average_likes > 0">{{average_likes}} {{$t("search.likes")}}</div>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_likes">
              <component
                :is="vue_slider"
                class="mt-3"
                v-model="average_likes"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="2000"
                :min="0"
                :interval="20"
                @drag-end="changeSlide"
                :tooltip-formatter="formatter"
              />
            </div>
          </div>

          <!-- Average retweets in 30 days -->
          <div class="py-2">
            <b-form-group class="mb-0">
              <span class="toggle-collapse-filters cursor-pointer" @click="toggleCollapse('average_retweets')">
                <strong><b-icon class="search-icon" icon="share"/>{{$t("search.average_retweets")}}</strong>
                <feather-icon class="chev-more-filters" icon="ChevronDownIcon" ref="chev_down_average_retweets"/>
                <feather-icon class="chev-more-filters d-none" icon="ChevronUpIcon" ref="chev_up_average_retweets"/>
              </span>              
              <p class="text-left font-weight-bold mt-1 mb-0" v-if="average_retweets">{{separatebycomma(average_retweets)}} Retweets </p>
            </b-form-group>
            <div class="d-none mt-1" ref="collapse_filters_average_retweets">
              <component
                :is="vue_slider"
                :tooltip="'none'"
                class="mt-1"
                v-model="average_retweets"
                :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :max="2000"
                :min="0"
                :interval="20"
                @drag-end="changeSlide"
              />      
            </div>
          </div>
        </b-col>
      </b-row>
       <template #footer>
        <!-- Button submit -->
        <b-col cols="12" class="button-submit-searcher col-12 mb-3 d-flex flex-wrap">
          <button-submit-filters :searched="searched" :loading="loading" :text="getText()" :total_results="total_results" @sendFilters="sendFilters()" @closeSideBar="closeSideBar()"/>

          <b-button class="m-2" variant="outline-secondary" @click="clearFilters()">
            <b-icon icon="trash" class="cursor-pointer"/>
            {{$t('search.reset_filters')}}
          </b-button>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BFormSelect, BSidebar, BDropdown, BDropdownHeader, BLink, VBTooltip
} from 'bootstrap-vue'
import service from '@/services/search'
import config from '@/services/config';
import { getFiltersUsedLabel, separatebycomma, getFormat } from '@/libs/utils/formats'

export default {
  components: {
    BSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownHeader,
    BLink,
    OptionsFilters: () => import('@/views/components/button-group/OptionsFilters.vue'),
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue'),
    FollowersFilter: () => import('@/views/buscador/filters/followers.vue'),
    buttonSubmitFilters: () => import('./buttonSubmitFilters.vue'),
    applyFilters: () => import('./filters/applyFilters.vue'),
    SearchBy: () => import('@/views/components/inputs/SearchBy.vue'),
    viewAvgSlider: () => import('./viewAvgSlider.vue'),
    selectNetworkSearch: () => import('./filters/components/selectNetworkSearch.vue'),
    searchByActions: () => import('@/views/buscador/filters/components/searchByActions.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    initial_filters: {
      type: Object,
      default: () => {}
    },
    networks_avaibles: {
      type: Array,
      default: () => {
        return []
      }
    },
    select_network: {
      type: Object,
      default: () => {
        return {}
      }
    },
    filters_history: {
      type: Object,
      default: () => {}
    },
    main_locations_filter: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      separatebycomma,
      vue_slider: null,
      update_info_contact: false,
      update_is_verified: false,
      update_search_by: false,
      update_followers_filter: false,
      update_location: false,
      open_sidebar: false,
      loading: false,
      total_results: 0,
      cancel_location: null,
      selected_location: null,
      influencer_language: null,
      is_verified: null,
      info_contact: null,
      engagement_rate: 0,
      followers_days: null,
      percentage_followers_growth: 50,
      most_recent: 0,
      average_tweets: 0,
      average_replies: 0,
      average_likes: 0,
      average_retweets: 0,
      text_influencer_location: this.$t('search.realty.any'),
      influencer_locations: [{}],
      change_influencer_location: false,
      influencer_locations_id: [],
      influencer_locations_type: [],
      text_followers: this.$t('search.realty.any'),
      followers_from: null,
      followers_to: null,
      formatter: v => `${separatebycomma(v)}`,
      filters: [
        {
          filter: 'audience_locations',
          text: this.$t("search.audienceLocation"),
          models: [
            {name: 'influencer_locations', value: null, default: [{}]},
            {name: 'text_influencer_location', value: null, default: this.$t('search.realty.any')}
          ],
          update_component: 'update_location',
          value: null,
        },  
        {
          filter: 'audience_size',
          text: this.$t("search.audienceSize"),
          models: [
            {name: 'followers_from', value: null, default: null},
            {name: 'followers_to', value: null, default: null},
            {name: 'text_followers', value: null, default: this.$t('search.realty.any')},
          ],
          update_component: 'update_followers_filter',
          value: null,
        },
        {
          filter: 'tweets_in_30',
          text: this.$t("search.averageTweets"),
          models: [{name: 'average_tweets', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'retweets_in_30',
          text: this.$t("search.averageRetweets"),
          models: [{name: 'average_retweets', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'influencer_language',
          text: this.$t("search.influencerLanguage"),
          models: [{name: 'influencer_language', value: null, default: null}],
          value: null,
        },
        {
          filter: 'is_verified',
          text: this.$t("search.onlyveri"),
          models: [{name: 'is_verified', value: null, default: null}],
          update_component: 'update_is_verified',
          value: null,
        },
        {
          filter: 'info_contact',
          text: this.$t("search.contact"),
          models: [{name: 'info_contact', value: null, default: null}],
          update_component: 'update_info_contact',
          value: null,
        },
        {
          filter: 'engagement_rate',
          text: this.$t("search.engagementRate"),
          models: [{name: 'engagement_rate', value: null, default: 0},],
          value: null,
        },
        {
          filter: 'follower_days',
          text: this.$t("search.increaseFollowers"),
          models: [
            {name: 'followers_days', value: null, default: null},
            {name: 'percentage_followers_growth', value: null, default: 50}
          ],
          value: null,
        },
        {
          filter: 'most_recent',
          text: this.$t("search.mostRecent"),
          models: [{name: 'most_recent', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'average_replies',
          text: this.$t("search.averageReplies"),
          models: [{name: 'average_replies', value: null, default: 0}],
          value: null,
        },
        {
          filter: 'average_likes',
          text: this.$t("search.averageLikes"),
          models: [{name: 'average_likes', value: null, default: 0}],
          value: null,
        },
      ],
      searched: true,
      keywords: [],
      where: 'anywhere',
      typing_timer: null,
      cancel_search_ws: null,
      loaded_services_history: true
    }
  },
  computed: {
  },
  created () {
    this.searched = true
  },
  async mounted() {
    this.vue_slider = (await import('vue-slider-component')).default;
    this.axios = (await import('axios')).default;
    setTimeout(() => {
      if (this.initial_filters && Object.keys(this.initial_filters).length > 0) {
        this.initialSearch()
      } else if (Object.keys(this.filters_history).length === 0) {
        this.getCounter()
      }
    }, 200);
  },
  methods: {
    updateSelectNetwork(select_network) {
      this.$emit('update_select_network', select_network)
    },
    initialSearch() {
      const filters = {}
      let name_country = ''
      Object.keys(this.initial_filters).forEach((key) => {
        if (key !== 'st') {
          if (key === 'influencer_location') {
            filters['influencer_location_id'] = [this.initial_filters[key][0].id]
            filters['influencer_location_type'] = [this.initial_filters[key][0].type]
            name_country = this.initial_filters[key][0].name
          } else if (key === 'followers_growth') {
            filters['followers_growth_period'] = parseInt(this.initial_filters[key][0].id)
            filters['followers_growth_to'] = this.initial_filters[key][0].prc
          } else if (key === 'audience_gender') {
            filters['audience_gender_id'] = parseInt(this.initial_filters[key][0].id)
            filters['audience_gender_from'] = this.initial_filters[key][0].prc
          } else if (key === 'influencer_category') {
            filters['influencer_yt_category'] = [parseInt(this.initial_filters[key][0].id.replace('2_', ''))]
          } else if (key === 'audience_age') {
            filters['audience_age_from'] = this.initial_filters[key][0].from
            filters['audience_age_p'] = this.initial_filters[key][0].prc
          } else if (key === 'followers') {
            filters['followers_from'] = this.initial_filters[key][0].from
            filters['followers_to'] = this.initial_filters[key][0].to
          } else if (key === 'sort_order') {
            filters[key] = this.initial_filters[key]
          } else if (key === 'sort_type') {
            filters[key] = this.initial_filters[key]
          } else if (key === 'er') {
            filters[key] = this.initial_filters[key][0].from
          } else {
            filters[key] = this.initial_filters[key][0].id
          }
        } else {
          filters['st'] = 'tr'
        }
      })

      this.formatApplyFilters(filters, name_country)
      this.$emit('clean_selected');
      this.searched = true

      this.$emit('callTwitter', filters, true)
      this.getCounter(filters)
    },
    formatFiltersHistory(filters) {
      this.loaded_services_history = false;
      this.formatApplyFilters(filters, '')
      this.$emit('callTwitter', filters, true)
      this.getCounter(filters)
    },
    changeSlideComponent(identifier, new_range) {
      this[identifier] = new_range
      this.getCounter()  
      
      const new_models = [{name: 'average_tweets', value: new_range > 0 ? new_range : null}]
      this.changeFilter('tweets_in_30', new_models, separatebycomma(new_range))
    },
    formatApplyFilters(filters, name_country) {

      Object.keys(filters).forEach(key => {
        if (key === 'influencer_location_id') {
          const filter = this.filters.find(f => f.filter === 'audience_locations')
          this.text_influencer_location = filters['influencer_location_text'] ? filters['influencer_location_text'] : this.$t('search.realty.any')
          filter.value = `${name_country.length === 0 ? this.text_influencer_location : name_country}`
          // const country = this.text_influencer_location.split(',')[0]
          filter.models[0].value = [{id: filter[key], name: name_country.length === 0 ? this.text_influencer_location : name_country, type: filters['audience_location_type']}]
        } else if (key === 'tr_30d_retweets') {
          this.average_retweets = filters[key];
        } else if (key === 'tr_30d_tweets') {
          this.average_tweets = filters[key];
        } else if (key === 'followers_from') {
          const filter = this.filters.find(f => f.filter === 'audience_size')
          filter.models[0].value = filters[key]
          filter.models[1].value = filters['followers_to']
          filter.value = `${getFormat(filters[key])}-${getFormat(filters['followers_to'])}`

          this.followers_size = [filters[key], filters['followers_to']]

          if (this.followers_size[0] && this.followers_size[1]) {
            this.text_followers = `${getFormat(this.followers_size[0])}-${getFormat(this.followers_size[1])}`
          } else if (this.followers_from) {
            this.text_followers = `≥${getFormat(this.followers_size[0])}`
          } else if (this.followers_to) {
            this.text_followers = `≤${getFormat(this.followers_size[1])}`
          }
        } else if (key === 'er') {
          const new_models = [{name: 'engagement_rate', value: filters[key]}]
          this.changeFilter('engagement_rate', new_models, filters[key])
          this.engagement_rate = filters[key]
        } else if (key === 'followers_growth_to') {
          const filter = this.filters.find(f => f.filter === 'follower_days')
          const period = parseInt(filters['followers_growth_period'])
          filter.models[0].value = period
          filter.models[1].value = filters[key]
          this.followers_days = filters['followers_growth_period'];
          this.percentage_followers_growth = filters['followers_growth_to']
          filter.value = `${this.$t('days').find((item) => item.value === period).text}, ${filters[key]}%`
        } else if (key === 'keywords') {
          filters[key].forEach((i, index) => {
            this.filters.push(
              {
                filter: `search_by_keywords_${index}`,
                text: this.$t(`search.${i.type_keyword}`),
                models: [{name: 'keywords', value: 'fdsa', default: ''}],
                value: i.keyword,
                multiple_values: false
              },
            )            
          })
        } else if (key === 'influencer_language' || key === 'is_verified' || key === 'has_contacts') {
          const key_filter = key !== 'has_contacts' ? key : 'info_contact';
          const filter = this.filters.find((f) => f.filter === key_filter)
          filter.models[0].value = filters[key]
          filter.value = filters[key]
          this[key] = filters[key];
        } else if (key === 'recent_post') {
          this.most_recent = filters[key];
        } else if (key === 'tr_30d_likes') {
          this.average_likes = filters[key];
        } else if (key === 'tr_30d_replies') {
          this.average_replies = filters[key];
        }
      })
    },
    filterVerified(options) {
      this.is_verified = options.value
    },
    filterHasContacts(options) {
      this.info_contact = options.value
    },
    addKeywordFilter(new_models, keywords, where = {}) {
      this.keywords = keywords;
      this.getCounter();
      
      if (where.tag_deleted !== null) this.filters = this.filters.filter((i) => i.value !== where.tag_deleted);
      if (where.deleted_all) this.filters = this.filters.filter((i) => !i.filter.includes('search_by_keywords'))
      keywords.forEach((i, index) => {
        if (!this.filters.find(j => j.value === i.keyword)) {
          this.filters.push(
            {
              filter: `search_by_keywords_${index}`,
              text: this.$t(`search.${i.type_keyword}`),
              models: [{name: 'keywords', value: null, default: ''}],
              value: null,
              multiple_values: false
            },
          )
          this.changeFilter(`search_by_keywords_${index}`, new_models, i.keyword);
        }
      })
    },
    getCounterInputHandleIt(prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })
      this.loading = true;
      this.total_results = 0

      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlPhp}/api/v1/buscador/influencers`,
        headers: { 'brandme-key': process.env.VUE_APP_BRANDME_KEY},
        data:{
          ...filters
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.total_results = response.data.data.total ? response.data.data.total : 0
        this.loading = false;
      })
    },
    deleteFilter(name, get_counter = true) {
      const to_deleted = this.filters.find((item) => item.filter === name);
      if (name.includes('search_by_keywords')) {
        this.$refs.search_by_actions.$refs.search_by_category.keywords_array = this.$refs.search_by_actions.$refs.search_by_category.keywords_array.filter(i => i.keyword !== to_deleted.value)
        this.keywords = this.keywords.filter(i => i.keyword !== to_deleted.value)
      }

      to_deleted.models.forEach((item) => {
        if (!name.includes('search_by_keywords')) this[item.name] = item.default;
        item.value = null;
      });
      if (to_deleted.update_component) this[to_deleted.update_component] = !this[to_deleted.update_component];
      to_deleted.value = null;
      this.closeCollapse(to_deleted.filter);
      if (get_counter) this.getCounter();
      this.searched = true
      if (name.includes('search_by_keywords')) this.filters = this.filters.filter(i => i.filter !== name)
    },
    checkFiltersUsed() {
      return this.filters.filter((item) => item.models[0].value !== null);
    },
    clearFilters(loading_results = true, send_service = true) {
      this.filters.forEach(item => {
        this.deleteFilter(item.filter, false)
      })

      if (send_service) {
        this.sendFilters(loading_results);
        this.getCounter();
      }

      this.searched = true
      this.open_sidebar = false;
    },
    closeCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.contains('d-none') ? '' : this.$refs[`collapse_filters_${id}`].classList.add('d-none');
        this.$refs[`chev_up_${id}`].classList.add('d-none');
        this.$refs[`chev_down_${id}`].classList.contains('d-none') ? this.$refs[`chev_down_${id}`].classList.remove('d-none') : '';
      }
    },
    toggleCollapse(id) {
      if (this.$refs[`collapse_filters_${id}`]) {
        this.$refs[`collapse_filters_${id}`].classList.toggle('d-none');
        this.$refs[`chev_down_${id}`].classList.toggle('d-none');
        this.$refs[`chev_up_${id}`].classList.toggle('d-none');
      }
    },
    changeLocationInfluencer(data) {
      const index = data.key
      this.influencer_locations[index].id = data.location.id
      this.influencer_locations[index].type = data.location.type
      this.influencer_locations[index].name = data.location.name

      const location_length = this.influencer_locations.filter((item) => item.id).length;

      this.influencer_locations.map((item) => {
        if (item.id) {
          const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
          this.text_influencer_location = `${item.name}, ${additional_text}`;
        }
      });
      const new_models = [{name: 'influencer_locations', value: this.influencer_locations}]
      this.changeFilter('audience_locations', new_models, this.text_influencer_location)

      this.getCounter()
    },
    removeInfluencerLocation(location, index, force_remove = false) {
      const self = this
      setTimeout(function() {
        const is_hosw = self.$refs.filter_influencer_location.$el.querySelector('ul').classList.contains('show')
        if (!is_hosw && force_remove) self.$refs.filter_influencer_location.$el.querySelector('button').click()
      }, 5)

      if (force_remove) this.influencer_locations.splice(index, 1)
      else this.influencer_locations[index] = {}

      if (!location) return

      if (this.influencer_locations.length === 0) this.text_influencer_location = this.$t('search.realty.any');
      else {
        let counter_empty = 0
        const location_length = this.influencer_locations.filter((item) => item.id).length;
        this.influencer_locations.map((item) => {
          if (item.id) {
            const additional_text = location_length > 1 ? `+${location_length - 1}` : '';
            this.text_influencer_location = `${item.name}, ${additional_text}`;
          } else {
            counter_empty += 1;
          }
          if (this.influencer_locations.length === counter_empty) {
            this.text_influencer_location = this.$t('search.realty.any');
          }
        });

      }
      this.getCounter()
    },
    addInfluencerLocation() {
      this.influencer_locations.push({})
    },
    formatInfluencerLocations() {
      this.influencer_locations_id = []
      this.influencer_locations_type = []
      if (this.influencer_locations.length > 0) {
        this.influencer_locations.forEach(location => {
          if (location.id) {
            this.influencer_locations_id.push(location.id)
            this.influencer_locations_type.push(location.type)
          }
        })
      }
    },
    getText() {
      if (this.loading) return this.$t("search.searching")
      if (this.total_results > 0 && this.searched) return `${getFormat(this.total_results)} ${this.$t("search.results")}`
      if (!this.searched) return `${getFormat(this.total_results)} ${this.$t("search.showResults")}`
      return this.$t('search.noResults')
    },
    changeFollowers(data) {
      this.followers_from = data.from
      this.followers_to = data.to
      let value_model = data.from + data.to
      if (this.followers_from && this.followers_to) {
        this.text_followers = `${getFormat(this.followers_from)}-${getFormat(this.followers_to)}`
      } else if (this.followers_from) {
        this.text_followers = `≥${getFormat(this.followers_from)}`
      } else if (this.followers_to) {
        this.text_followers = `≤${getFormat(this.followers_to)}`
      } else {
        this.text_followers = this.$t('search.realty.any')
        value_model = null
      }
      const new_models = [
        {name: 'followers_from', value: value_model},
        {name: 'followers_to', value: this.followers_to},
      ]
      this.changeFilter('audience_size', new_models, this.text_followers)

      this.getCounter()
    },
    getCounter (prev_filters = false) {
      this.searched = false
      const filters = prev_filters ? prev_filters : this.getFilters()
      filters.counter = 1;

      service.saveLogSearch(filters)
        .then(() => {
        })
      
      this.loading = true;
      this.total_results = 0
      service.getResults(filters)
        .then(response => {
          if (Object.keys(this.filters_history).length > 0) this.loaded_services_history = true;
          this.total_results = response.data.total
          this.loading = false;
        }).catch(() => {
          this.total_results = 0
          this.loading = false;
        })
    },
    getFilters () {
      const finalFilters = {}
      this.formatInfluencerLocations()
      const data = {
        hash: localStorage.getItem('accessToken'),
        st: 'tr',
        keywords: this.keywords.length !== 0 ? this.keywords : null,
        where: this.where === 'anywhere' ? null : this.where,
        followers_from: this.followers_from,
        followers_to: this.followers_to,
        influencer_location_id: this.influencer_locations_id.length > 0 ? this.influencer_locations_id : null,
        influencer_location_type : this.influencer_locations_type.length > 0 ? this.influencer_locations_type : null,
        influencer_location_text: this.text_influencer_location !== this.$t('search.realty.any') ? this.text_influencer_location : null,
        influencer_language: this.influencer_language,
        is_verified: this.is_verified,
        has_contacts: this.info_contact,
        er: this.engagement_rate > 0 ? this.engagement_rate : null,
        followers_growth_period: this.followers_days,
        followers_growth_to: this.followers_days ? this.percentage_followers_growth : null,
        recent_post: this.most_recent > 0 ? this.most_recent : null,
        tr_30d_tweets: this.average_tweets > 0 ? this.average_tweets : null,
        tr_30d_replies: this.average_replies > 0 ? this.average_replies : null,
        tr_30d_likes: this.average_likes > 0 ? this.average_likes : null,
        tr_30d_retweets: this.average_retweets > 0 ? this.average_retweets : null,
        total_results: this.total_results,
        filters_label_used: getFiltersUsedLabel(this.filters)
      }
      const dataKeys = Object.keys(data)
      for (let i = 0; i < dataKeys.length; i++) {
        const dataKey = dataKeys[i]
        if (data[dataKey] !== null) {
          finalFilters[dataKey] = data[dataKey]
        }
      }

      return finalFilters
    },
    sendFilters (loading_results = true) {
      this.searched = true
      const finalFilters = this.getFilters()
      this.$emit('callTwitter', finalFilters, loading_results)
      this.$emit('clean_selected');
    },
    closeSideBar() {
      this.open_sidebar = false
    },
    changeSlide() {
      this.getCounter()
    },
    changeFilter(name_filter, new_models, value_filter) {
      const filter = this.filters.find(item => item.filter === name_filter)

      filter.models.forEach(model => {
        const new_model = new_models.find(mod => mod.name === model.name)

        if (new_model) {
          model.value = new_model.value
        }
      })
      filter.value = value_filter
    },
    updateModel(data) {
      if (data.name_emit) {
        this[data.name_emit](...data.params)
      }
    }
  },
  watch: {
    loading(val) {
      this.$emit('change_loading', val);
    },
    filters_history(val, old_val) {
      this.clearFilters(true, false);
      if (val && Object.keys(val).length > 0 && (JSON.stringify(val) !== JSON.stringify(old_val))) {
        this.formatFiltersHistory(val, '')
      }
    },
    initial_filters(val) {
      if (Object.keys(val).length > 0) this.initialSearch()
    },
    '$i18n.locale'() {
      if (this.influencer_locations.length === 1 && !this.influencer_locations[0].id) {
        this.text_influencer_location = this.$t('search.realty.any')
      }
    },
    average_tweets(val) {
      if (val) {
        const new_models = [{name: 'average_tweets', value: val > 0 ? val : null}]
        this.changeFilter('tweets_in_30', new_models, separatebycomma(val))
      }
    },
    average_retweets(val) {
      const new_models = [{name: 'average_retweets', value: val > 0 ? val : null}]
      this.changeFilter('retweets_in_30', new_models, separatebycomma(val))
    },
    influencer_language(val) {
      const new_models = [{name: 'influencer_language', value: this.$t('instaIdioma').find((item) => item.value === val).value}]
      this.changeFilter('influencer_language', new_models, this.$t('instaIdioma').find((item) => item.value === val).text)

      if (this.loaded_services_history) this.getCounter()
    },
    is_verified(val) {
      const new_models = [{name: 'is_verified', value: this.$t('instaVerificado').find((item) => item.value === val).value}]
      this.changeFilter('is_verified', new_models, this.$t('instaVerificado').find((item) => item.value === val).text)

      if (this.loaded_services_history) this.getCounter()
    },
    info_contact(val) {
      const new_models = [{name: 'info_contact', value: this.$t('instaContacto').find((item) => item.value === val).value}]
      this.changeFilter('info_contact', new_models, this.$t('instaContacto').find((item) => item.value === val).text)
      if (this.loaded_services_history) this.getCounter()
    },
    engagement_rate(val) {
      const new_models = [{name: 'engagement_rate', value: val !== 0 ? val : null}]
      this.changeFilter('engagement_rate', new_models, val !== 0 ? val : null)
    },
    followers_days(val) {
      const new_models = [{name: 'followers_days', value: this.$t('days').find((item) => item.value === val).value}]
      this.changeFilter('follower_days', new_models, `${this.$t('days').find((item) => item.value === val).text}, ${this.percentage_followers_growth}%`)

      if (this.loaded_services_history) this.getCounter()
    },
    percentage_followers_growth(val) {
      this.changeFilter('follower_days', [], `${this.$t('days').find((item) => item.value === this.followers_days).text}, ${val}%`)
    },
    most_recent(val) { 
      const new_models = [{name: 'most_recent', value: val !== 0 ? val : null}]
      this.changeFilter('most_recent', new_models, val !== 0 ? val : null)
    },
    average_replies(val) {
      const new_models = [{name: 'average_replies', value: val !== 0 ? val : null}]
      this.changeFilter('average_replies', new_models, val !== 0 ? separatebycomma(val) : null)
    },
    average_likes(val) {
      const new_models = [{name: 'average_likes', value: val !== 0 ? val : null}]
      this.changeFilter('average_likes', new_models, val !== 0 ? separatebycomma(val) : null)
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>
<style lang="scss">
#card-twitter-filters > .card-body {
  padding: 8px 20px 8px 8px;
  display: flex;
  align-items: center;
  @media(max-width: 991px) {
    flex-wrap: wrap;
    padding: 8px;
  }
}
.select-network-search {
  width: auto;
  @media(max-width: 991px) {
    width: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
.p-custom {
  padding: 0 1.28rem 0.5rem 1.28rem;
}
.mr-03 {
  margin-right: 0.3em;
}
.container-more-filters {
  @media(max-width: 991px) {
    width: 100%;
    margin-top: 1em;
  }
  @media(min-width: 992px) {
    margin-left: 1em;
  }
}
</style>